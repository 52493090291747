const {
  REACT_APP_API,
  REACT_APP_API_FIREBASE,
  REACT_APP_API_FIREBASE_LANDING,
  REACT_APP_DOMAIN,
  REACT_APP_SHARE_DOMAIN_PREFIX,
  REACT_APP_BUNDLE_ID,
  REACT_APP_FIREBASE,
  REACT_APP_FACEBOOK_APP_ID,
  REACT_APP_HELPHERO_APP_ID,
  REACT_APP_FACEBOOK_CLIENT_TOKEN,
  REACT_APP_GOOGLE_CLOUD_TRANSLATE_API_KEY,
  REACT_APP_GOOGLE_APP_ID,
} = process.env ?? {};

import Device from 'modules/device';

const config: Config = {
  API: REACT_APP_API || process.env.REACT_APP_API,
  API_FIREBASE: REACT_APP_API_FIREBASE || process.env.REACT_APP_API_FIREBASE,
  API_FIREBASE_LANDING:
    REACT_APP_API_FIREBASE_LANDING ||
    process.env.REACT_APP_API_FIREBASE_LANDING,
  FIREBASE: JSON.parse(
    REACT_APP_FIREBASE || (process.env.REACT_APP_FIREBASE as string),
  ),
  DOMAIN: REACT_APP_DOMAIN || process.env.REACT_APP_DOMAIN,
  BUNDLE_ID: REACT_APP_BUNDLE_ID || process.env.REACT_APP_BUNDLE_ID,
  SHARE_DOMAIN_PREFIX:
    REACT_APP_SHARE_DOMAIN_PREFIX || process.env.REACT_APP_SHARE_DOMAIN_PREFIX,
  APP_VERSION: Device.version,
  FACEBOOK_APP_ID:
    REACT_APP_FACEBOOK_APP_ID || process.env.REACT_APP_FACEBOOK_APP_ID,
  FACEBOOK_CLIENT_TOKEN:
    REACT_APP_FACEBOOK_CLIENT_TOKEN || process.env.REACT_APP_FACEBOOK_CLIENT_TOKEN,
  HELPHERO_APP_ID:
    REACT_APP_HELPHERO_APP_ID || process.env.REACT_APP_HELPHERO_APP_ID,
  GOOGLE_CLOUD_TRANSLATE_API_KEY:
    REACT_APP_GOOGLE_CLOUD_TRANSLATE_API_KEY || process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API_KEY,
  GOOGLE_APP_ID:
    REACT_APP_GOOGLE_APP_ID || process.env.REACT_APP_GOOGLE_APP_ID,
};

export default config;
