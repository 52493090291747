import { gql } from '@apollo/client';

export const typeDefs = gql`
  type ContentAccess {
    edit: Boolean!
    remove: Boolean!
  }

  extend type Post {
    contentAccess: ContentAccess
  }
`;
