import { isEmpty } from 'ramda';

import { ONBOARDING_STEPS } from 'app/constants';
import { NAV_TYPES_ONBOARDING_STACK } from '../constants';

export const getOnboardingInitialRoute = (steps: string[] = []) => {
  if (isEmpty(steps)) {
    return NAV_TYPES_ONBOARDING_STACK.PHOTOS;
  }
  // if (!steps.includes(ONBOARDING_STEPS.PHONE)) {
  //   return NAV_TYPES_ONBOARDING_STACK.PHONE;
  // }
  if (!steps.includes(ONBOARDING_STEPS.MAIN_INTEREST)) {
    return NAV_TYPES_ONBOARDING_STACK.MAIN_INTEREST;
  }
  // if (steps.includes(ONBOARDING_STEPS.CLAIM_YOUR_DIAGNOSIS) && !steps.includes(ONBOARDING_STEPS.HAVE_YOU_BEEN_DIAGNOSED)) {
  //   return NAV_TYPES_ONBOARDING_STACK.HOW_DO_YOU_FEEL;
  // }
  // if (!steps.includes(ONBOARDING_STEPS.HAVE_YOU_BEEN_DIAGNOSED)) {
  //   return NAV_TYPES_ONBOARDING_STACK.MAIN_INTEREST;
  // }
  // if (!steps.includes(ONBOARDING_STEPS.HOW_DO_YOU_FEEL)) {
  //   return NAV_TYPES_ONBOARDING_STACK.HOW_DO_YOU_FEEL;
  // }

  return NAV_TYPES_ONBOARDING_STACK.PHOTOS;
};
