import React, { Component } from 'react';

import { StyleSheet } from 'react-native';
import { styled, StyledComponentProps } from 'ui/components';

interface Props extends StyledComponentProps {
  IconComponent: any;
  style: any;
  width?: number;
  height?: number;
}

class IconWrapper extends Component<Props> {
  static styledComponentName = 'Icon';

  render() {
    const {
      IconComponent,
      themedStyle = {},
      style,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      dispatch,
      ...props
    } = this.props;

    const { color, ...iconProps } = themedStyle;

    const iconStyle = StyleSheet.flatten(style);

    return (
      <IconComponent fill={color} {...iconProps} {...props} style={iconStyle} />
    );
  }
}

export default styled(IconWrapper);
