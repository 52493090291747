import { createContext, useContext, useEffect, useCallback } from 'react';
import { Platform } from 'react-native';

import AnalyticsService from './AnalyticsService';
import { ExtraParams } from './types';
import { EVENTS } from './events';
import { capitalCase, pascalSnakeCase } from 'utils/string';

const analyticsContext = createContext({ service: AnalyticsService });

export const AnalyticsProvider = analyticsContext.Provider;

export const useAnalytics = () => {
  const context = useContext(analyticsContext);

  return context.service;
};

export const useAnalyticsLogger = (
  name: EVENTS,
  isPlatformSpecific = false,
) => {
  const service = useAnalytics();

  const platform = Platform.OS == 'ios' ? 'iOS' : capitalCase(Platform.OS);

  return useCallback(
    (params: ExtraParams = {}) => {
      service.logEvent({
        name: isPlatformSpecific
          ? `${pascalSnakeCase(name)}_${platform}`
          : name,
        params,
      });
    },
    [name, service],
  );
};

export const useAnalyticsParametersSetter = (
  name: string,
  params: ExtraParams = {},
) => {
  const service = useAnalytics();

  useEffect(() => {
    service.setParamsByName(name, params);
  }, [name, params, service]);
};
