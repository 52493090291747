import { analytics } from 'services/firebase';
import reportError from 'utils/reportError';
import { isDevelopment } from 'app/constants';
import { ExtraParams } from './types';
import { FirebaseProvider, FacebookProvider } from './Providers';
import { Provider } from './Providers/types';
import { isWeb } from "../../utils/platform";

export type ProviderTypes = 'facebook' | 'firebase';

export interface Event {
  name: string;
  params?: ExtraParams;
  isUniq?: boolean;
}

type Providers<Q extends string> = { [index in Q]?: Provider };

class AnalyticsService<Q extends string> {
  private providers: Providers<Q> = {};
  private paramsByEvent: { [event: string]: ExtraParams } = {};

  constructor(providers: Providers<Q>) {
    this.providers = providers;
  }

  logEvent = (event: Event) => {
    // eslint-disable-next-line no-undef
    if (!isWeb && isDevelopment) {
      console.log('Analytics event:', event);
    }

    try {
      Object.values<Provider>(this.providers).forEach((provider) => {
        provider.logEvent(event);
      });
    } catch (error) {
      reportError({ error });
    }
  };

  setParamsByName = (name: string, params: ExtraParams) => {
    this.paramsByEvent[name] = {
      ...(this.paramsByEvent[name] || {}),
      ...params,
    };
  };
}

export default new AnalyticsService<ProviderTypes>({
  firebase: new FirebaseProvider(analytics()),
  facebook: new FacebookProvider(),
});
