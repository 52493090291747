const dialogs = {
  forgot: {
    title: "We've send you an email to reset your password!",
    description:
      "If you don't receive an e-mail within 5 minutes (check your junk folder), please contact info@healthread.com",
    ok: 'Ok, got it!',
  },
  invite: {
    title: "We've sent your invitation for your friends!",
    ok: 'Ok, got it!',
  },
};

const auth = {
  forgot: {
    title: 'Forgot password?',
    info:
      'Please enter your email address and we’ll help you reset it and get back on track.',
  },
};

const onboarding = {
  phone: {
    title: 'Thank you for registration!',
    info1: 'Please choose your country and enter your full phone number',
    info2:
      'No worry, we never give your number to strangers. Other users will see it only after your permission in the settings.',
    button: {
      continue: 'Continue',
      back: 'Back to sign in',
      finish: 'Finish',
    },
  },
  verification: {
    title: 'SMS verification',
    info: 'We’ve sent you a verification code. Please enter it below',
    question: 'Didn’t receive SMS?',
    button: {
      resend: 'Resend code',
    },
  },
};

export default {
  dialogs,
  auth,
  onboarding,
};
