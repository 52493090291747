import { gql } from '@apollo/client';

import { Country } from 'api/fragments/Country';

export const getCountryByCode = gql`
  query GetCountryByCode($code: String!) {
    data: getCountryByCode(code: $code) {
      ...Country
    }
  }
  ${Country}
`;
