import { gql } from '@apollo/client';

import { User } from 'api/fragments/User';

export const getCurrentUser = gql`
  query GetCurrentUser {
    data: getCurrentUser {
      ...User
    }
  }
  ${User}
`;
