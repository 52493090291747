import React, { FC } from 'react';

import { Layout, LayoutProps } from '../external';
import { styles } from './styles';

export const Row: FC<LayoutProps & { center?: boolean }> = ({
  style,
  center,
  ...props
}) => {
  return (
    <Layout
      style={[styles.container, center && styles.center, style]}
      {...props}
    />
  );
};
