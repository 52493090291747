import { gql } from '@apollo/client';

export const typename = 'Country';

export const Country = gql`
  fragment Country on Country {
    id
    code
    name
    phone
    emoji
    emojiU
  }
`;
