import React, { useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { LogBox, View } from 'react-native';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';

import { AuthProvider } from 'services/auth/context';
import { createClient } from 'api/createClient';
import Bootstrap from './Bootstrap';
import { useAuth } from 'services/auth/useAuth';
import { useTrackEvent } from 'hooks/useTrackEvent';
import { ReduxProvider } from './services/redux/Provider';
import Session from 'services/session';
import { EVENTS, useAnalyticsLogger } from 'services/analytics';
import { isWeb } from "./utils/platform";

const client = createClient();


if(!isWeb && __DEV__) {
  loadDevMessages();
  loadErrorMessages();
}

const AppOpenedTracker = () => {
  const { id } = useAuth();
  const { trackEvent } = useTrackEvent();

  const trackFirstOpen = useAnalyticsLogger(EVENTS.FIRST_OPEN, true);

  useEffect(() => {
    LogBox.ignoreLogs(['Animated: `useNativeDriver`']);
  }, []);

  useEffect(() => {
    if (id) {
      trackEvent('app_opened');
    }
  }, [id]);

  useEffect(() => {
    Session.isFirstLaunch()
      .then((isFirstLaunch) => {
        if (isFirstLaunch) {
          trackFirstOpen();
        }
      })
      .finally(() => Session.setLaunched());
  }, []);

  return <View />;
};

export default function App() {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ReduxProvider>
          <AppOpenedTracker />
          <ActionSheetProvider>
            <Bootstrap />
          </ActionSheetProvider>
        </ReduxProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}
