import { StyleSheet } from 'react-native';

import { COLORS, HORIZONTAL_INDENT } from 'components/ui-constants';

export default StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  lottieContainer: {
    backgroundColor: COLORS.white,
  },
  loadingIndicator: {
    padding: HORIZONTAL_INDENT,
  },
});

export const overlay = StyleSheet.create({
  container: {
    ...StyleSheet.absoluteFillObject,
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.overlay,
  },
});

export const bootstrap = StyleSheet.create({
  container: {
    backgroundColor: COLORS.splash,
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});
