import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { Thread_topic } from 'api/fragments/types/Thread';
import { RootState } from '../store';

export const name = 'thread';
export interface ThreadState {
  goToTopic: boolean;
  topicInfo?: {
    topic: Thread_topic;
    screenType: string;
    title: string;
    subTitle: string;
  };
  isFirstThreadCreated: boolean;
  shouldShowFirstThreadModal: boolean;
  openCreateThread: boolean;
  editThreadMode: boolean;
}

const initialState: ThreadState = {
  goToTopic: false,
  isFirstThreadCreated: false,
  shouldShowFirstThreadModal: false,
  openCreateThread: false,
  editThreadMode: false,
};

export const threadSlice: Slice<
  ThreadState,
  SliceCaseReducers<ThreadState>
> = createSlice<ThreadState, SliceCaseReducers<ThreadState>>({
  name,
  initialState,
  reducers: {
    setGoToTopic: (state, action) => {
      state.goToTopic = true;
      state.topicInfo = action.payload;
    },
    clearTopic: (state, action) => {
      state.goToTopic = action.payload;
      state.topicInfo = undefined;
    },
    setShouldShowFirstThreadModal: (state, action) => {
      state.shouldShowFirstThreadModal = action.payload;
    },
    setOpenCreateThread: (state, action) => {
      state.openCreateThread = action.payload;
    },
    setEditThreadMode: (state, action) => {
      state.editThreadMode = action.payload;
    },
  },
});

export const {
  setGoToTopic,
  clearTopic,
  setShouldShowFirstThreadModal,
  setOpenCreateThread,
  setEditThreadMode
} =
  threadSlice.actions;

export const selectThreadState = (state: RootState): ThreadState => state[name];
