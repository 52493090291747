import { SvgProps } from 'react-native-svg';
import React from 'react';
import { IconPack } from 'ui/components';
import { ReactComponent as groups } from 'assets/icons/groups.svg';
import { ReactComponent as home } from 'assets/icons/home.svg';
import { ReactComponent as messages } from 'assets/icons/messages.svg';
import { ReactComponent as people } from 'assets/icons/people.svg';
import { ReactComponent as profile } from 'assets/icons/profile.svg';
import { ReactComponent as mail } from 'assets/icons/mail.svg';
import { ReactComponent as phone } from 'assets/icons/phone.svg';
import { ReactComponent as more } from 'assets/icons/more.svg';
import { ReactComponent as forward } from 'assets/icons/forward.svg';
import { ReactComponent as settings } from 'assets/icons/settings.svg';
import { ReactComponent as editProfile } from 'assets/icons/edit-profile.svg';
import { ReactComponent as notifications } from 'assets/icons/notifications.svg';
import { ReactComponent as like } from 'assets/icons/like.svg';
import { ReactComponent as likeFull } from 'assets/icons/like_active.svg';
import { ReactComponent as support } from 'assets/icons/support.svg';
import { ReactComponent as supportFull } from 'assets/icons/support_active.svg';
import { ReactComponent as inNeedOfHelp } from 'assets/icons/in-need-of-help.svg';
import { ReactComponent as comment } from 'assets/icons/comment.svg';
import { ReactComponent as comments } from 'assets/icons/comments.svg';
import { ReactComponent as share } from 'assets/icons/share.svg';
import { ReactComponent as chevronDown } from 'assets/icons/chevron-down.svg';
import { ReactComponent as send } from 'assets/icons/send.svg';
import { ReactComponent as photo } from 'assets/icons/photo.svg';
import { ReactComponent as remove } from 'assets/icons/remove-icon.svg';
import { ReactComponent as cross } from 'assets/icons/cross.svg';
import { ReactComponent as play } from 'assets/icons/play.svg';
import { ReactComponent as search } from 'assets/icons/search.svg';
import { ReactComponent as bell } from 'assets/icons/bell.svg';
import { ReactComponent as binoculars } from 'assets/icons/binoculars.svg';
import { ReactComponent as checkmarkCircle } from 'assets/icons/checked-circled.svg';
import { ReactComponent as pointer } from 'assets/icons/pointer.svg';
import { ReactComponent as facebook } from 'assets/icons/fb.svg';
import { ReactComponent as google } from 'assets/icons/google-icon.svg';
import { ReactComponent as arrowLeft } from 'assets/icons/arrow-left.svg';
import { ReactComponent as arrowRight } from 'assets/icons/arrow-right.svg';
import { ReactComponent as arrowLeftThin } from 'assets/icons/arrow-left-thin.svg';
import { ReactComponent as tick } from 'assets/icons/tick.svg';
import { ReactComponent as doubleTick } from 'assets/icons/tick-double.svg';
import { ReactComponent as logoLight } from 'assets/icons/logo-light.svg';
import { ReactComponent as addImage } from 'assets/icons/add-image.svg';
import { ReactComponent as edit } from 'assets/icons/edit-icon.svg';
import { ReactComponent as magnifier } from 'assets/icons/magnifier.svg';
import { ReactComponent as trash } from 'assets/icons/trash.svg';
import { ReactComponent as move } from 'assets/icons/move.svg';
import { ReactComponent as reply } from 'assets/icons/reply.svg';
import { ReactComponent as plus } from 'assets/icons/plus.svg';
import { ReactComponent as professional } from 'assets/icons/star.svg';
import { ReactComponent as marker } from 'assets/icons/marker.svg';
import { ReactComponent as handset } from 'assets/icons/handset.svg';
import { ReactComponent as help } from 'assets/icons/help.svg';
import { ReactComponent as close } from 'assets/icons/close.svg';
import { ReactComponent as bookmarks } from 'assets/icons/bookmarks.svg';
import { ReactComponent as bookmarksSquare } from 'assets/icons/bookmarks-square.svg';
import { ReactComponent as bookmarksThread } from 'assets/icons/bookmarks-thread.svg';
import { ReactComponent as bookmarkOutline } from 'assets/icons/bookmark-outline.svg';
import { ReactComponent as hat } from 'assets/icons/hat.svg';
import { ReactComponent as roundedChevronDown } from 'assets/icons/rounded-chevron-down.svg';
import { ReactComponent as roundedChevronLeft } from 'assets/icons/rounded-chevron-left.svg';
import { ReactComponent as roundedChevronUp } from 'assets/icons/rounded-chevron-up.svg';
import { ReactComponent as question } from 'assets/icons/question.svg';
import { ReactComponent as filters } from 'assets/icons/filters.svg';
import { ReactComponent as smilingFace } from 'assets/icons/smiling-face.svg';
import { ReactComponent as birthday } from 'assets/icons/birthday.svg';
import { ReactComponent as proIcon } from 'assets/img/pro.svg';
import { ReactComponent as articles } from 'assets/icons/articles.svg';
import { ReactComponent as menu } from 'assets/icons/menu.svg';
import { ReactComponent as invitePeople } from 'assets/icons/account-multiple-plus.svg';
import { ReactComponent as check } from 'assets/icons/check.svg';
import { ReactComponent as agreeable } from 'assets/icons/agreeable.svg';
import { ReactComponent as angry } from 'assets/icons/angry.svg';
import { ReactComponent as boringFace } from 'assets/icons/boring-face.svg';
import { ReactComponent as sadFace } from 'assets/icons/sad-face.svg';
import { ReactComponent as sick } from 'assets/icons/sick.svg';
import { ReactComponent as idea } from 'assets/icons/idea.svg';
import { ReactComponent as ideaFull } from 'assets/icons/idea_active.svg';
import { ReactComponent as ambition } from 'assets/icons/ambition.svg';
import { ReactComponent as creative } from 'assets/icons/creative.svg';
import { ReactComponent as productive } from 'assets/icons/productive.svg';
import { ReactComponent as negative } from 'assets/icons/negative.svg';
import { ReactComponent as agreeableLarge } from 'assets/icons/agreeable-large.svg';
import { ReactComponent as angryLarge } from 'assets/icons/angry-large.svg';
import { ReactComponent as checkboxChecked } from 'assets/icons/checkbox-checked.svg';
import { ReactComponent as checkboxUnchecked } from 'assets/icons/checkbox-unchecked.svg';
import { ReactComponent as expand } from 'assets/icons/expand.svg';
import { ReactComponent as checkmark } from 'assets/icons/checkmark.svg';
import { ReactComponent as makingIt } from 'assets/icons/making-it.svg';
import { ReactComponent as feelingBetter } from 'assets/icons/feeling-better.svg';
import { ReactComponent as feelingStrong } from 'assets/icons/feeling-strong.svg';
import { ReactComponent as lessStressed } from 'assets/icons/less-stressed.svg';
import { ReactComponent as anxious } from 'assets/icons/anxious.svg';
import { ReactComponent as checked } from 'assets/icons/checked.svg';
import { ReactComponent as undecided } from 'assets/icons/undecided.svg';
import { ReactComponent as noData } from 'assets/icons/no-data.svg';
import { ReactComponent as chevronLeft } from 'assets/icons/chevron-backward.svg';
import { ReactComponent as chevronRight } from 'assets/icons/chevron-forward.svg';
import { ReactComponent as chevronRightGrey } from 'assets/icons/chevron-forward-grey.svg';
import { ReactComponent as healthCheckIn } from 'assets/icons/health-check-in.svg';
import { ReactComponent as notificationBell } from 'assets/icons/notification-bell.svg';
import { ReactComponent as threads } from 'assets/icons/threads.svg';
import { ReactComponent as surveyStart } from 'assets/icons/survey-start.svg';
import { ReactComponent as surveyEnd } from 'assets/icons/survey-end.svg';
import { ReactComponent as chevronLeftGreen } from 'assets/icons/chevron-left.svg';
import { ReactComponent as warning } from 'assets/icons/warning.svg';
import { ReactComponent as LikeIcon } from 'assets/icons/new-like.svg';
import { ReactComponent as LikeCompleted } from 'assets/icons/LikeCompleted.svg';
import { ReactComponent as likeSelected } from 'assets/icons/like-selected.svg';
import { ReactComponent as informSelected } from 'assets/icons/inform-selected.svg';
import { ReactComponent as connectSelected } from 'assets/icons/connect-selected.svg';
import { ReactComponent as supportSelected } from 'assets/icons/support-selected.svg';
import { ReactComponent as hug } from 'assets/icons/hug.svg';
import { ReactComponent as hugSelected } from 'assets/icons/hug-selected.svg';
import { ReactComponent as handshake } from 'assets/icons/handshake.svg';
import { ReactComponent as handshakeSelected } from 'assets/icons/handshake-selected.svg';
import { ReactComponent as alarm } from 'assets/icons/alarm.svg';
import { ReactComponent as symptomsChecker } from 'assets/icons/symptoms-checker.svg';
import { ReactComponent as firstThread } from 'assets/img/first-thread.svg';
import { ReactComponent as info } from 'assets/icons/info.svg';
import { ReactComponent as threadReply } from 'assets/icons/thread-reply.svg';
import { ReactComponent as createThread } from 'assets/icons/create-thread.svg';
import { ReactComponent as smallWhiteLogo } from 'assets/icons/small-white-logo.svg';
import { ReactComponent as messageIconThreads } from 'assets/icons/message-icon-threads.svg';
import { ReactComponent as createReplyThread } from 'assets/icons/create-reply-thread.svg';

import IconWrapper from './IconWrapper';

const icons = {
  undecided,
  checked,
  expand,
  checkmark,
  ambition,
  productive,
  negative,
  creative,
  anxious,
  ['making-it']: makingIt,
  ['no-data']: noData,
  ['feeling-better']: feelingBetter,
  ['feeling-strong']: feelingStrong,
  ['less-stressed']: lessStressed,
  ['checkbox-checked']: checkboxChecked,
  ['checkbox-unchecked']: checkboxUnchecked,
  ['agreeable-large']: agreeableLarge,
  ['in-need-of-help']: inNeedOfHelp,
  ['angry-large']: angryLarge,
  agreeable,
  angry,
  groups,
  home,
  check,
  messages,
  people,
  profile,
  mail,
  phone,
  more,
  forward,
  settings,
  sick,
  notifications,
  ['sad-face']: sadFace,
  ['edit-profile']: editProfile,
  ['boring-face']: boringFace,
  edit: edit,
  like,
  ['like-full']: likeFull,
  support,
  ['support-full']: supportFull,
  ['smiling-face']: smilingFace,
  comment,
  comments,
  share,
  ['chevron-down']: chevronDown,
  send,
  photo,
  remove,
  cross,
  play,
  search,
  bell,
  binoculars,
  ['checkmark-circle']: checkmarkCircle,
  pointer,
  facebook,
  ['arrow-right']: arrowRight,
  ['arrow-left']: arrowLeft,
  ['arrow-left-thin']: arrowLeftThin,
  tick,
  ['tick-double']: doubleTick,
  ['add-image']: addImage,
  ['logo-light']: logoLight,
  magnifier,
  trash,
  move,
  reply,
  plus,
  professional,
  marker,
  handset,
  hat,
  help,
  close,
  bookmarks,
  ['bookmarks-square']: bookmarksSquare,
  ['bookmarks-thread']: bookmarksThread,
  ['bookmark-outline']: bookmarkOutline,
  ['rounded-chevron-down']: roundedChevronDown,
  ['rounded-chevron-left']: roundedChevronLeft,
  ['rounded-chevron-up']: roundedChevronUp,
  question,
  filters,
  proIcon,
  articles,
  birthday,
  menu,
  google,
  ['invite-people']: invitePeople,
  idea,
  ['idea-full']: ideaFull,
  ['chevron-left']: chevronLeft,
  ['chevron-right']: chevronRight,
  ['chevron-right-grey']: chevronRightGrey,
  ['health-check-in']: healthCheckIn,
  ['notification-bell']: notificationBell,
  threads,
  ['survey-start']: surveyStart,
  ['survey-end']: surveyEnd,
  ['chevron-left-green']: chevronLeftGreen,
  warning,
  ['like-icon']: LikeIcon,
  ['like-completed']: LikeCompleted,
  ['like-selected']: likeSelected,
  ['inform-selected']: informSelected,
  ['connect-selected']: connectSelected,
  ['support-selected']: supportSelected,
  hug,
  ['hug-selected']: hugSelected,
  handshake,
  ['handshake-selected']: handshakeSelected,
  alarm,
  ['symptoms-checker']: symptomsChecker,
  ['first-thread']: firstThread,
  info,
  ['thread-reply']: threadReply,
  ['create-thread']: createThread,
  ['small-white-logo']: smallWhiteLogo,
  ['message-icon-threads']: messageIconThreads,
  ['create-reply-thread']: createReplyThread,
};

const IconProvider = (Component) => ({
  toReactElement: (props) => {
    if (Component) {
      return <IconWrapper IconComponent={Component} {...props} />;
    }

    return null;
  },
});

function createIconsMap() {
  return new Proxy(
    {},
    {
      get(_, name) {
        return IconProvider(icons[name]);
      },
    },
  );
}

export const iconsPack: IconPack<SvgProps> = {
  name: 'healthread',
  icons: createIconsMap(),
};
