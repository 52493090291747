import { light } from '@eva-design/eva';

export const healthreadContrast = {
  ...light,
  'color-nav-bar': '#71B8CB',
  'color-nav-header': '#387292',
  'color-basic-100': '#FFFFFF',
  'color-basic-200': '#F7F9FC',
  'color-basic-300': '#EDF1F7',
  'color-basic-400': '#E4E9F2',
  'color-basic-500': '#C1CEDE',
  'color-basic-600': '#6f81a5',
  'color-basic-700': '#2E3A59',
  'color-basic-800': '#222B45',
  'color-basic-900': '#1A2138',
  'color-basic-1000': '#151A30',
  'color-basic-1100': '#101426',

  'color-primary-100': '#D5F9F4',
  'color-primary-200': '#ADF4EF',
  'color-primary-300': '#7EDDDE',
  'color-primary-400': '#57B4BD',
  'color-primary-500': '#298192',
  'color-primary-600': '#1D667D',
  'color-primary-700': '#144E69',
  'color-primary-800': '#0D3854',
  'color-primary-900': '#072846',
  'color-primary-transparent-100': 'rgba(41, 129, 146, 0.08)',
  'color-primary-transparent-200': 'rgba(41, 129, 146, 0.16)',
  'color-primary-transparent-300': 'rgba(41, 129, 146, 0.24)',
  'color-primary-transparent-400': 'rgba(41, 129, 146, 0.32)',
  'color-primary-transparent-500': 'rgba(41, 129, 146, 0.4)',
  'color-primary-transparent-600': 'rgba(41, 129, 146, 0.48)',
  'color-success-100': '#D5F9D1',
  'color-success-200': '#A6F4A6',
  'color-success-300': '#74E07D',
  'color-success-400': '#4CC162',
  'color-success-500': '#1E9941',
  'color-success-600': '#15833F',
  'color-success-700': '#0F6E3C',
  'color-success-800': '#095837',
  'color-success-900': '#054933',
  'color-success-transparent-100': 'rgba(30, 153, 65, 0.08)',
  'color-success-transparent-200': 'rgba(30, 153, 65, 0.16)',
  'color-success-transparent-300': 'rgba(30, 153, 65, 0.24)',
  'color-success-transparent-400': 'rgba(30, 153, 65, 0.32)',
  'color-success-transparent-500': 'rgba(30, 153, 65, 0.4)',
  'color-success-transparent-600': 'rgba(30, 153, 65, 0.48)',
  'color-info-100': '#DFE8F8',
  'color-info-200': '#C1D2F1',
  'color-info-300': '#97ABD7',
  'color-info-400': '#6E81AF',
  'color-info-500': '#3E4E7B',
  'color-info-600': '#2D3B69',
  'color-info-700': '#1F2B58',
  'color-info-800': '#131D47',
  'color-info-900': '#0B133B',
  'color-info-transparent-100': 'rgba(62, 78, 123, 0.08)',
  'color-info-transparent-200': 'rgba(62, 78, 123, 0.16)',
  'color-info-transparent-300': 'rgba(62, 78, 123, 0.24)',
  'color-info-transparent-400': 'rgba(62, 78, 123, 0.32)',
  'color-info-transparent-500': 'rgba(62, 78, 123, 0.4)',
  'color-info-transparent-600': 'rgba(62, 78, 123, 0.48)',
  'color-warning-100': '#FAEDD6',
  'color-warning-200': '#F6D7AF',
  'color-warning-300': '#E6B482',
  'color-warning-400': '#CE8F5E',
  'color-warning-500': '#AE6030',
  'color-warning-600': '#954723',
  'color-warning-700': '#7D3218',
  'color-warning-800': '#64200F',
  'color-warning-900': '#531309',
  'color-warning-transparent-100': 'rgba(174, 96, 48, 0.08)',
  'color-warning-transparent-200': 'rgba(174, 96, 48, 0.16)',
  'color-warning-transparent-300': 'rgba(174, 96, 48, 0.24)',
  'color-warning-transparent-400': 'rgba(174, 96, 48, 0.32)',
  'color-warning-transparent-500': 'rgba(174, 96, 48, 0.4)',
  'color-warning-transparent-600': 'rgba(174, 96, 48, 0.48)',
  'color-danger-100': '#FBE0D5',
  'color-danger-200': '#F7BAAC',
  'color-danger-300': '#E9897F',
  'color-danger-400': '#D35C5B',
  'color-danger-500': '#B72C37',
  'color-danger-600': '#9D2035',
  'color-danger-700': '#831632',
  'color-danger-800': '#6A0E2E',
  'color-danger-900': '#57082B',
  'color-danger-transparent-100': 'rgba(183, 44, 55, 0.08)',
  'color-danger-transparent-200': 'rgba(183, 44, 55, 0.16)',
  'color-danger-transparent-300': 'rgba(183, 44, 55, 0.24)',
  'color-danger-transparent-400': 'rgba(183, 44, 55, 0.32)',
  'color-danger-transparent-500': 'rgba(183, 44, 55, 0.4)',
  'color-danger-transparent-600': 'rgba(183, 44, 55, 0.48)',
  'color-control': 'rgba(255, 103, 89, 0.48)',

  'color-system-control-default': '#007BFA',
  'color-facebook': '#2553b4',
  'color-google': '#d34836',
};
