class FacebookProvider {
  async login() {}

  logout() {}

  async isAuthorized(): Promise<boolean> {
    return true;
  }
}

export default new FacebookProvider();
