import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  container: {
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: 20,
    flex: 1,
    height: 36,
  },
  icon: {
    marginLeft: 12,
  },
  input: {
    flex: 1,
    marginTop: 5,
  },
});
