import { StyleSheet } from 'react-native';

import { screenHorizontalPadding } from '../../spacing';

export const styles = StyleSheet.create({
  content: {
    flex: 1,
    paddingHorizontal: screenHorizontalPadding,
  },
});
