import { gql } from '@apollo/client';

import { Attachment } from './Attachment';

export const typename = 'Album';

export const Album = gql`
  fragment Album on Album {
    id
    kind
    name
    attachments {
      ...Attachment
    }
  }
  ${Attachment}
`;
