import {
  auth,
  database,
  FirebaseAuthTypes,
  FirebaseDatabaseTypes,
} from 'services/firebase';

class FirebaseProvider {
  constructor(
    private authService: FirebaseAuthTypes.Module,
    private databaseService: FirebaseDatabaseTypes.Module,
  ) {
    this.authService = authService;
  }

  async login({ token }) {
    await this.authService.signInWithCustomToken(token);
    await this.databaseService.goOnline();
  }

  async logout() {
    await this.databaseService.goOffline();
    await this.authService.signOut();
  }

  isAuthorized(): Promise<boolean> {
    return new Promise(resolve => {
      const unsubscriber = this.authService.onAuthStateChanged(user => {
        unsubscriber();

        if (user) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }
}

export default new FirebaseProvider(auth(), database());
