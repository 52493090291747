import { crashlytics } from 'services/firebase';
import { isDevelopment } from 'app/constants';
import { isWeb } from "./platform";

export type Crashlytics = typeof crashlytics;

export interface ErrorPayload {
  error: Error;
  code?: number;
}

class ReportBuilder {
  add(name: string, value: object) {
    const service = crashlytics();

    if (!isWeb && isDevelopment) {
      return console.log(name, value);
    }

    service.setAttribute(name, JSON.stringify(value));
  }
}

export const reportError = (
  { error }: ErrorPayload,
  reportBuilder?: (builder: ReportBuilder) => void,
) => {
  const service = crashlytics();

  if (reportBuilder) {
    reportBuilder(new ReportBuilder());
  }

  if (!isWeb && isDevelopment) {
    return console.log('reportError', error);
  }

  service.recordError(error);
};

export const report = (reportBuilder: (builder: ReportBuilder) => void) => {
  if (reportBuilder) {
    reportBuilder(new ReportBuilder());
  }
};

export const onErrorHandler = (error: Error) => reportError({ error });

export default reportError;
