import { useEffect, useState, useCallback } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

export const PREFIX = 'v1:settings:';

export const KEY_WALK_THROUGH_SCREEN_SEEN = 'walk-through-screen-seen';

export const KEY_TUTORIAL_SEEN = 'tutorial-seen';

type Value = string | boolean;

export const getLocalStorage = async (name: string, fallback?: Value) => {
  try {
    const result = await AsyncStorage.getItem(`${PREFIX}${name}`);
    if (result) {
      return JSON.parse(result);
    }
  } catch {}

  return fallback;
};

export function setLocalStorage<T>(name: string, value: T) {
  try {
    AsyncStorage.setItem(`${PREFIX}${name}`, JSON.stringify(value));
  } catch {}
}

function useAppSettings<T extends Value>(
  field: string,
  defaultValue: T,
): [T, (value: T) => void] {
  const [value, setValue] = useState<T>();

  useEffect(() => {
    const run = async () => {
      const item = await getLocalStorage(field, defaultValue);
      setValue(item);
    };
    run();
  }, [field, defaultValue]);

  const update = useCallback(
    (nextValue: T) => {
      setLocalStorage(field, nextValue);
      setValue(nextValue);
    },
    [field],
  );

  return [value as T, update];
}

export default useAppSettings;
