import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';

import { createEvent } from 'api/mutations/createEvent';
import {
  CreateEvent,
  CreateEventVariables,
} from 'api/mutations/types/CreateEvent';

export enum EventKind {
  appOpened = 'app_opened',
  dailyCheckInViewed = 'daily_check_in_viewed',
  postViewed = 'post_viewed',
  postsListed = 'posts_listed',
  profileViewed = 'profile_viewed',
  threadListed = 'threads_listed',
  threadViewed = 'thread_viewed',
  journalViewed = 'journal_viewed',
  userOnboarded = 'user_onboarded',
  articleViewed = 'article_viewed',
}

export enum EventType {
  post = 'Post',
  topicThread = 'TopicThread',
  user = 'User',
  journal = 'Journal',
}

export function useTrackEvent() {
  const [mutate, result] = useMutation<CreateEvent, CreateEventVariables>(
    createEvent,
  );

  return {
    data: result.data,
    loading: result.loading,
    trackEvent: (
      kind: string,
      eventableType?: string,
      eventableId?: string,
    ) => {
      console.log('trackEvent', kind, eventableType, eventableId);
      return mutate({
        variables: {
          input: { eventableId, eventableType, kind },
        },
      });
    },
  };
}

export const useTrackEventOnMount = (kind: EventKind) => {
  const { trackEvent } = useTrackEvent();

  useEffect(() => {
    trackEvent(kind);
  }, []);
};

type TrackStatus = 'init' | 'loading' | 'done';

export const useTrackEventOnReady = (
  kind: string,
  eventableType?: string,
  eventableId?: string,
) => {
  const { trackEvent } = useTrackEvent();
  const [trackStatus, setTrackStatus] = useState<TrackStatus>('init');

  useEffect(() => {
    if (trackStatus === 'init' && eventableId && eventableType && kind) {
      setTrackStatus('loading');
      trackEvent(kind, eventableType, eventableId)
        .then(() => {
          setTrackStatus('done');
        })
        .catch(() => {
          setTrackStatus('init');
        });
    }
  }, [eventableId, eventableType, kind, trackStatus]);
};
