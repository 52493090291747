import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { statePersistor, store } from './store';

export const ReduxProvider: FC = ({ children }) => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={statePersistor}>
      {children}
    </PersistGate>
  </Provider>
);
