import { mapping as defaultMapping } from '@eva-design/eva';
import React, { Fragment, FC } from 'react';

import { Theming } from 'ui/themes/Theming';
import { appThemes } from 'ui/themes/appThemes';
import { mapping as customMapping } from 'ui/themes/mapping';
import { ApplicationProvider, IconRegistry } from '../components';
import { iconsPack } from './iconsPack';

export const ThemeProvider: FC<{ theme: string }> = ({
  children,
  theme = 'light',
  ...props
}) => {
  const [themeContext, currentTheme] = Theming.useTheming(
    appThemes,
    customMapping,
    theme,
  );

  return (
    <Fragment>
      <IconRegistry icons={iconsPack} />
      <ApplicationProvider
        theme={currentTheme}
        mapping={defaultMapping}
        customMapping={customMapping}
        {...props}
      >
        <Theming.ThemeContext.Provider value={themeContext}>
          {children}
        </Theming.ThemeContext.Provider>
      </ApplicationProvider>
    </Fragment>
  );
};
