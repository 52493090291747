import { Platform } from 'react-native';

export const NAV_TYPES = {
  LOADING: 'loading',
  LANDING: 'landing',
  MY_STORY_LINE: 'my_story_line',
  ONBOARDING: 'onboarding',
  AUTH: 'auth',
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  FORGOT_PASSWORD: 'forgot_password',
  MAIN: 'main',
  WALKTHROUGH: 'walkthrough',
  PRIVACY: 'privacy',
  TERMS_AND_CONDITIONS: 'terms_and_conditions',
  EMAIL_CONFIRMATION: 'email_confirmation',
  PEOPLE: 'people',
  HOME: 'home',
  THREADS: 'threads',
  CREATE_THREAD: 'create_thread',
  VIEW_THREAD: 'view_thread',
  VIEW_TOPIC_THREAD: 'view_topic_thread',
  CREATE_THREAD_INTERESTS: 'create_thread_interests',
  SEARCH_THREADS: 'search_threads',
  SELECT_THREADS_TOPIC: 'select_threads_topic',
  SELECTED_TOPIC: 'selected_topic',
  GROUPS: 'groups',
  GROUPS_DISCOVER: 'groups_discover',
  SEARCH_GROUPS: 'search_groups',
  SEARCH_GROUPS_INTERESTS: 'search_groups_interests',
  CHAT: 'chat',
  PROFILE: 'profile',
  PROFILE_INDEX: 'profile_index',
  FEED: 'feed',
  HEALTH_CHECK_IN: 'health_check',
  FEED_SEARCH: 'feed_search',
  ARTICLES_SEARCH: 'articles_search',
  USER_PROFILE: 'user_profile', // profiles of other users
  POST_VIEW: 'post_view',
  JOURNAL_VIEW: 'journal_view',
  ARTICLE_VIEW: 'article_view',
  POST_CREATE: 'post_create',
  EMOTION_CREATE: 'emotion_create',
  POST_CREATE_SEARCH_INTERESTS: 'post_create_search_interests',
  POST_EDIT: 'post_edit',
  FOLLOWERS: 'followers',
  FOLLOWING: 'following',
  INTERESTS_FEED: 'interests_feed',
  INTERESTS_ARTICLES: 'interests_articles',
  PROFILE_PUBLIC: 'profile_public',
  PROFILE_EDIT: 'profile_edit',
  PROFILE_EDIT_OVERVIEW: 'profile_edit_overview',
  PROFILE_INTERESTS: 'profile_interests',
  PROFILE_EDIT_PHONE: 'profile_edit_phone',
  PROFILE_EDIT_INTERESTS: 'profile_edit_interests',
  PROFILE_SWITCH: 'profile_switch',
  PROFILE_PRO_INFO: 'profile_pro_info',
  PROFILE_EDIT_PRO_INFO: 'profile_edit_pro_info',
  NOTIFICATIONS_SCREEN: 'notifications',
  SETTINGS: 'settings',
  SETTINGS_CHANGE_PASSWORD: 'settings_change_password',
  SETTINGS_APPEARANCE: 'settings_appearance',
  SETTINGS_ACCOUNT_STATUS: 'settings_account_status',
  SETTINGS_ABOUT_US: 'settings_about_us',
  SETTINGS_TERMS: 'settings_terms',
  SETTINGS_PRIVACY: 'settings_privacy',
  SETTINGS_FAQ: 'settings_faq',
  SETTINGS_FAQ_DETAILS: 'settings_faq_details',
  SEARCH_COUNTRIES: 'search_countries',
  SEARCH_INTERESTS: 'search_interests',
  SEARCH_FOLLOWERS: 'search_followers',
  USERS: 'users',
  USERS_SUGGESTED: 'users_suggested',
  USERS_SEARCH: 'users_search',
  USERS_SEARCH_INTERESTS: 'users_search_interests',
  USERS_SEARCH_FILTERS: 'users_search_filters',
  CONTACT_US: 'contact_us',
  REPORT_USER: 'report_user',
  GROUPS_SUGGESTED: 'groups_suggested',
  GROUPS_SUGGESTED_HOME: 'groups_suggested_home',
  GROUP_DETAILS: 'group_details',
  GROUP_SETTINGS: 'group_settings',
  GROUP_JOIN: 'group_join',
  BOOKMARKS: 'bookmarks',
  ARTICLES: 'articles',
  ARTICLES_BY_CATEGORY: 'articles_by_category',
  INVITE_FRIENDS: 'INVITE_FRIENDS',
  MANAGE_DIAGNOSES: 'MANAGE_DIAGNOSES',
  MANAGE_DIAGNOSES_LIST: 'MANAGE_DIAGNOSES_LIST',
  CREATE_DIAGNOSES: 'CREATE_DIAGNOSES',
  CREATE_POST_INTERESTS: 'CREATE_POST_INTERESTS',
  MY_STORY_LINE_DETAILS: 'MY_STORY_LINE_DETAILS',
  FEED_ONBOARDING_POPUP: 'feed?open_popup',
  PLUS_BUTTON: 'PLUS_BUTTON',
  PLUS_BUTTON_MODAL: 'PLUS_BUTTON_MODAL',
  COMMUNITY_GUIDELINES: 'community_guidelines',
  FEED_PLUS_POPUP: 'feed_plus_popup',
  SYMPTOMS_CHECKER: 'SYMPTOMS_CHECKER',
  SYMPTOMS_RESULT: 'SYMPTOMS_RESULT',
  SYMPTOMS_CHECKER_WEB: 'symptoms-checker',
  DELETE_USER: 'delete_user'
};

export const IS_IOS = Platform.OS === 'ios';
export const IS_WEB = Platform.OS === 'web';
export const IS_ANDROID = Platform.OS === 'android';

export const PRETTY_DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_SERVER = 'YYYY-MM-DD';

export enum GENDER {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
}

export const GENDER_NAMES = {
  [GENDER.FEMALE]: 'Female',
  [GENDER.MALE]: 'Male',
  [GENDER.OTHER]: 'Other',
};

export const UK = 'UK';
export const AUS = 'AUS';
export const NZ = 'NZ';

export const KEY_AUTH = 'KEY_AUTH';

export const ONBOARDING_STEPS = {
  // PHONE: 'PHONE',
  PHOTOS: 'PHOTOS',
  MAIN_INTEREST: 'MAIN_INTEREST',
  // INTERESTS: 'INTERESTS',
  // HAVE_YOU_BEEN_DIAGNOSED: 'HAVE_YOU_BEEN_DIAGNOSED',
  // CLAIM_YOUR_DIAGNOSIS: 'CLAIM_YOUR_DIAGNOSIS',
  // HOW_DO_YOU_FEEL: 'HOW_DO_YOU_FEEL',
};

export const ONBOARDING_STEPS_COUNT = Object.keys(ONBOARDING_STEPS).length;

export const ONBOARDIND_FIELDS = [
  'homeLocation',
  'discoverGroups',
  'aboutMe',
  'avatars',
  'reasons',
];

export const PROFILE_COMPLETED = -1;

export const SAFE_AREA = {
  BOTTOM: { top: 'never', bottom: 'always' },
  TOP: { top: 'always', bottom: 'never' },
  VERTICAL: { vertical: 'always' },
};

export enum POST_TYPE {
  USER = 'user',
  ADVERTISEMENT = 'advertisement',
  INFO = 'info',
  SYSTEM = 'system',
  JOURNAL = 'journal',
}

export const EXTRA_SCROLL_HEIGHT = Platform.select({ ios: 0, android: 50 });

export enum PROFILE_TYPE {
  REGULAR = 'regular',
  PRO = 'pro',
  ADMIN = 'admin',
}

export const PROFILE_NAME = {
  [PROFILE_TYPE.REGULAR]: 'User',
  [PROFILE_TYPE.PRO]: 'Pro',
};

export const CONTENT_TYPE = {
  [POST_TYPE.ADVERTISEMENT]: 'Advertising',
  [POST_TYPE.INFO]: 'Article',
};

export enum ATTACHMENT_TYPE {
  IMAGE = 'image',
  VIDEO = 'video',
}

export enum BADGE {
  POST_LIKED = 'postLiked',
  POST_COMMENTED = 'postCommented',
  POST_SUPPORTED = 'postSupported',
  USER_FOLLOWER = 'userFollower',
  GROUP_INVITATION = 'groupInvitation',
  NEW_CHAT = 'newChat',
  CHAT_MESSAGE_CREATED = 'chatMessageCreated',
  GROUP_MESSAGE_CREATED = 'groupMessageCreated',
}

export const MAX_POST_LENGTH = 250;

export const COMMENTABLE_TYPE = {
  POST: 'Post',
};

export enum THEME {
  DEFAULT = 'light',
  CONTRAST = 'contrast',
}

export const THREADS_TYPES = {
  REPLIED: 'replied',
  ALL_THREADS: 'all_threads',
  TOPIC_THREADS: 'topic_threads',
  CREATE_THREAD: 'create_thread',
  VIEW_TOPIC_THREADS: 'view_topic_threads',
};

export const FONT_SCALE_170 = 1.7;
export const FONT_SCALE_160 = 1.6;
export const FONT_SCALE_150 = 1.5;
export const FONT_SCALE_140 = 1.4;
export const FONT_SCALE_120 = 1.2;

export const storeLink = {
  ios: 'https://apps.apple.com/app/id1498450688',
  android:
    'https://play.google.com/store/apps/details?id=com.healthread.app&hl=en',
};

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export const isDevelopment = (window && window.__DEV__) || false;
