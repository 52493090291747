import { Optional } from 'utility-types';
import React from 'react';

import { Modal, ModalProps, Spinner } from '../external';
import { styles } from './styles';

type Props = Optional<ModalProps, 'children'>;

export const SpinnerOverlay = ({
  visible,
  backdropStyle,
  style,
  children = <Spinner status="basic" size="medium" />,
  ...props
}: Props) => (
  <Modal
    visible={visible}
    backdropStyle={[styles.backdrop, backdropStyle]}
    style={[styles.container, style]}
    {...props}
  >
    {children}
  </Modal>
);
