import React, { FC } from 'react';

import useStyles from 'ui/useStyles';
import { Icon, InputProps } from '../index';
import styles from './styles';
import { Layout } from "@ui-kitten/components";

interface SearchInputProps extends InputProps {
  additionalStyles: any;
}

export const SearchInput: FC<SearchInputProps> = props => {
  const background = useStyles(theme => ({
    backgroundColor: theme['color-basic-300'],
  }));
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    props.onChangeText(event.target.value);

  return (
    <Layout style={[styles.container, background, props.additionalStyles]}>
      <Icon name="magnifier" size="small" style={styles.icon} />
      <input
        {...props}
        onChange={handleChange}
        className="search-input"
      />
    </Layout>
  );
};
