import { getCountry, findBestAvailableLanguage, getLocales } from 'react-native-localize';
import i18n, { Scope } from 'i18n-js';

import enUs from './en-us/translations';

i18n.fallbacks = true;
i18n.missingBehaviour = 'guess';
i18n.translations = {
  en: enUs,
  'en-US': enUs,
};

const fallback = { languageTag: 'en', isRTL: false };

const { languageTag } =
  findBestAvailableLanguage(Object.keys(i18n.translations)) || fallback;

i18n.locale = languageTag;

export const translate = (scope: Scope) => i18n.translate(scope);
export const t = translate;

export const countryCode = getCountry();

export const locales = getLocales();
