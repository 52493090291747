import { useReducer } from 'react';
import { User } from 'api/fragments/types/User';

interface ReducerState {
  loading: boolean;
  bootstrapped: boolean;
  user?: User;
  error?: Error;
}

const initialState: ReducerState = {
  loading: true,
  bootstrapped: false,
};

type Action =
  | { type: 'reset' }
  | { type: 'request' }
  | { type: 'success'; payload?: User | undefined }
  | { type: 'failure'; error: Error };

const reducer = (state: ReducerState, action: Action) => {
  switch (action.type) {
    case 'reset':
      return {
        ...state,
        user: undefined,
        error: undefined,
      };
    case 'request':
      return {
        ...state,
        loading: true,
        error: undefined,
      };
    case 'success':
      return {
        ...state,
        user: action.payload,
        loading: false,
        bootstrapped: true,
        error: undefined,
      };
    case 'failure':
      return {
        ...state,
        loading: false,
        bootstrapped: true,
        error: action.error,
      };
    default:
      return state;
  }
};

export const useAuthReducer = () => {
  return useReducer(reducer, initialState);
};
