export enum EVENTS {
  ARTICLE_ADD_BOOKMARK = 'ARTICLE_ADD_BOOKMARK',
  BOOKMARK = 'BOOKMARK',
  BOOKMARK_CONTENT = 'BOOKMARK_CONTENT',
  CLICK_ARTICLE_OPTIONS = 'CLICK_ARTICLE_OPTIONS',
  CLICK_ARTICLES_CATEGORY = 'CLICK_ARTICLES_CATEGORY',
  CLICK_EDITORS_PICK = 'CLICK_EDITORS_PICK',
  COMPLETED_ONBOARDING = 'COMPLETED_ONBOARDING',
  COMPLETED_REGISTRATION = 'COMPLETED_REGISTRATION',
  CREATE_FOLLOW = 'CREATE_FOLLOW',
  CREATE_JOURNAL = 'CREATE_JOURNAL',
  CREATE_POST = 'CREATE_POST',
  CREATE_THREAD = 'CREATE_THREAD',
  DAILY_CHECK_IN = 'DAILY_CHECK_IN',
  DEEP_LINK_OPENED = 'DEEP_LINK_OPENED',
  FIRST_OPEN = 'FIRST_OPEN',
  INPUT_EMOTION_DAILY_CHECK_IN = 'INPUT_EMOTION_DAILY_CHECK_IN',
  INPUT_REACTION_IN_POST = 'INPUT_REACTION_IN_POST',
  PROMOTION_ANSWER = 'PROMOTION_ANSWER',
  PROMOTION_CLOSE = 'PROMOTION_CLOSE',
  READ_ARTICLE = 'READ_ARTICLE',
  REPLY_JOURNAL = 'REPLY_JOURNAL',
  REPLY_POST = 'REPLY_POST',
  REPLY_THREAD = 'REPLY_THREAD',
  SEARCH_POSTS = 'SEARCH_POSTS',
  SHARE_ARTICLE = 'SHARE_ARTICLE',
  SYMPTOM_CHECK = 'SYMPTOM_CHECK',
  THREAD_CREATE = 'THREAD_CREATE',
  THREAD_REPLY = 'THREAD_REPLY',
  VIEW_ARTICLE = 'VIEW_ARTICLE',
  VIEW_ARTICLES_TIME = 'VIEW_ARTICLES_TIME',
  VIEW_FEED_TIME = 'VIEW_FEED_TIME',
  VIEW_PROMOTION_TIME = 'VIEW_PROMOTION_TIME',
}
