import { useEffect, useState, createContext } from 'react';
import useAppSettings from 'hooks/useAppSettings';
import { DEFAULT_APP_SETTINGS } from 'app/settings';

export type Theme = 'light' | 'contrast';

export interface ThemeContextValue {
  /**
   * Should return the name of current theme
   */
  currentTheme: Theme;
  /**
   * Should switch theme globally
   */
  setCurrentTheme: (theme: Theme) => void;
  /**
   * Should create a theme based on current
   */
  createTheme: (upstreamTheme: Theme) => any;
}

export class Theming {
  static ThemeContext = createContext<ThemeContextValue>(null);

  static useTheming = (
    themes: Record<Theme, any>,
    mapping,
    theme: Theme,
  ): [ThemeContextValue, any] => {
    const [currentTheme, setCurrentTheme] = useState<Theme>(theme);
    const [, persistTheme] = useAppSettings(
      'theme',
      DEFAULT_APP_SETTINGS.theme,
    );

    useEffect(() => {
      setCurrentTheme(theme);
    }, [theme]);

    const createTheme = (upstreamTheme: Theme): any => {
      return themes[upstreamTheme];
    };

    const themeContext: ThemeContextValue = {
      currentTheme,
      setCurrentTheme: nextTheme => {
        persistTheme(nextTheme);
        setCurrentTheme(nextTheme);
      },
      createTheme,
    };

    return [themeContext, themes[currentTheme]];
  };
}
