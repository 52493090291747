import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const name = 'thanks';
export interface ThanksState {
  goToCheckIn: boolean;
  shouldClearHealthState: boolean;
  latestEmotionSelected?: string;
  navigateToGraph?: string | null;
}

const initialState: ThanksState = {
  goToCheckIn: false,
  shouldClearHealthState: false,
};

export const thanksSlice: Slice<
  ThanksState,
  SliceCaseReducers<ThanksState>
> = createSlice<ThanksState, SliceCaseReducers<ThanksState>>({
  name,
  initialState,
  reducers: {
    setGoToCheckIn: (state, action) => {
      state.goToCheckIn = action.payload;
    },
    setShouldClearHealthState: (state, action) => {
      state.shouldClearHealthState = action.payload;
    },
    setLatestEmotionSelected: (state, action) => {
      state.latestEmotionSelected = action.payload;
    },
    setNavigateToGraph: (state, action) => {
      state.navigateToGraph = action.payload;
    },
  },
});

export const {
  setGoToCheckIn,
  setShouldClearHealthState,
  setLatestEmotionSelected,
  setNavigateToGraph,
} = thanksSlice.actions;

export const selectGoToCheckIn = (state: RootState) => state[name].goToCheckIn;
export const selectShouldClearHealthState = (state: RootState) =>
  state[name].shouldClearHealthState;
export const selectLatestEmotionSelected = (state: RootState) =>
  state[name].latestEmotionSelected;
export const selectNavigateToGraph = (state: RootState) =>
  state[name].navigateToGraph;
