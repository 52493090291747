import React, { ReactNode, FC } from 'react';
import {
  StyleProp,
  ViewStyle,
  ScrollView,
  ScrollViewProps,
} from 'react-native';

import useStyles from 'ui/useStyles';
import { styles } from './styles';

interface Props extends ScrollViewProps {
  style?: StyleProp<ViewStyle>;
  children?: ReactNode;
}

export const ScreenLayout: FC<Props> = ({ children, style, ...props }) => {
  const container = useStyles(theme => ({
    backgroundColor: theme['color-basic-100'],
  }));

  return (
    <ScrollView
      keyboardShouldPersistTaps="handled"
      contentContainerStyle={[styles.content, container, style]}
      {...props}
    >
      {children}
    </ScrollView>
  );
};
