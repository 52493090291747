import Session, { Tokens } from 'services/session';

import FirebaseProvider from './firebase';
import FacebookProvider from './facebook';
import { TokenError } from './errors';

class AuthService {
  async authorize(tokens?: Tokens | null) {
    if (!tokens) {
      throw new TokenError();
    }

    await Session.update(tokens);

    await FirebaseProvider.login({ token: tokens.firebaseToken });
  }

  async isAuthorized() {
    const backend = await Session.getAccessToken();
    const firebase = await FirebaseProvider.isAuthorized();

    return !!(backend && firebase);
  }

  async logout() {
    return Promise.all([
      Session.delete(),
      FirebaseProvider.logout(),
      FacebookProvider.logout(),
    ]);
  }
}

export default new AuthService();
