import { useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

const useBackgroundHandler = (handler: () => void) =>
  useEffect(() => {
    function onChange(state: AppStateStatus) {
      if (state === 'background' || state === 'inactive') {
        handler();
      }
    }

    const subscription = AppState.addEventListener('change', onChange);

    return () => {
      subscription.remove();
    };
  }, [handler]);

export default useBackgroundHandler;
