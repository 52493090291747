// Index file for web

import { AppRegistry } from 'react-native';
import App from './App';
import './index.css';

AppRegistry.registerComponent('Healthread', () => App);

AppRegistry.runApplication('Healthread', {
  rootTag: document.getElementById('root'),
});
