import { StyleService } from '@ui-kitten/components';
import { ViewStyle, TextStyle, ImageStyle } from 'react-native';

import useTheme from './useTheme';

const useStyles = (
  createStyles: (theme: {
    [key: string]: string;
  }) => ViewStyle | TextStyle | ImageStyle | { fill: string },
) => {
  const theme = useTheme();
  return StyleService.createThemedEntry(createStyles(theme), theme);
};

export default useStyles;
