  import ReactNativeFile from './reactNativeFile';
  
  const isReactNativeFile = (value: any): boolean => {
    return (
      value instanceof ReactNativeFile ||
      (value && typeof value.uri === 'string' && typeof value.name === 'string')
    );
  };
  
  export default isReactNativeFile;
 