import React from 'react';
import { View, ActivityIndicator, StyleProp, ViewStyle } from 'react-native';

import { COLORS } from 'components/ui-constants';
import { bootstrap } from './styles';

export interface Props {
  color?: string;
  size?: number | 'small' | 'large';
  style?: StyleProp<ViewStyle>;
}

const LoadingBootstrap = ({ color = COLORS.indicator, size, style }: Props) => (
  <View style={[bootstrap.container, style]}>
    <ActivityIndicator animating={true} color={color} size={size} />
  </View>
);

export default LoadingBootstrap;
