import { NavigationAction, NavigationActions } from '@react-navigation/core';

import { NAV_TYPES } from 'app/constants';
import { User } from 'api/fragments/types/User';
import { getOnboardingInitialRoute } from './getOnboardingInitialRoute';
import { getUrlLinking } from './getUrlLinking';

const Navigation = import('navigation/Navigation');
const PublicNavigation = import('navigation/PublicNavigation');

export async function getInitialNavigateAction(
  user?: User,
  isDeeplink = false,
): Promise<NavigationAction> {
  if (!user) {
    return PublicNavigation.then(module => getUrlLinking(module.default));
  }

  if (!user.profileCompleted) {
    return NavigationActions.navigate({
      routeName: getOnboardingInitialRoute(user.meta?.onboardingSteps || []),
    });
  }

  if (isDeeplink) {
    return Navigation.then(module => getUrlLinking(module.default));
  }

  return NavigationActions.navigate({ routeName: NAV_TYPES.MAIN });
}
