import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { onboardingSlice, OnboardingState } from './slices/OnboardingSlice';
import { thanksSlice, ThanksState } from './slices/ThanksSlice';
import { threadSlice, ThreadState } from './slices/ThreadSlice';

import { persistConfig } from './config';

const rootReducer = combineReducers({
  [onboardingSlice.name]: onboardingSlice.reducer,
  [thanksSlice.name]: thanksSlice.reducer,
  [threadSlice.name]: threadSlice.reducer,
});

const reducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }),
});
// export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export interface RootState {
  thanks: ThanksState;
  onboarding: OnboardingState;
  thread: ThreadState;
  // other slices...
}

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const statePersistor: ReturnType<typeof persistStore> =
  persistStore(store);

export type ReduxState = ReturnType<typeof store.getState>;
