import { Resolver } from '@apollo/client';
import { getCurrentUser } from 'api/queries/getCurrentUser';
import { Post } from 'api/fragments/types/Post';

export const getPostContentAccess: Resolver = async (
  rootValue: Post,
  variables,
  { cache },
) => {
  const { data: user } = cache.readQuery({ query: getCurrentUser });

  const hasAccess = rootValue.author.id === user.id;

  return {
    __typename: 'ContentAccess',
    edit: hasAccess,
    remove: hasAccess,
  };
};
