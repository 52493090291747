import urlParse from 'url-parse';
import { NavigationActions } from '@react-navigation/core';
import { NAV_TYPES } from 'app/constants';

const getPathAndParamsFromLink = (link: string) => {
  const parsed = urlParse(link, true);

  return {
    path: parsed.pathname.substr(1),
    params: parsed.query,
  };
};

export const getUrlLinking = (Navigation, url = window.location.href) => {
  const pathAndParams = getPathAndParamsFromLink(url);

  const data = Navigation.router.getActionForPathAndParams(
    pathAndParams.path,
    pathAndParams.params,
  );

  if (data) {
    return data;
  }

  return NavigationActions.navigate({ routeName: NAV_TYPES.MAIN });
};
