import { memo } from 'react';
import { usePresence } from 'hooks/usePresence';
import { useDeepLinkOpened } from 'hooks/useDeepLinkOpened';

const SideEffects = () => {
  usePresence();
  useDeepLinkOpened();

  return null;
};

export default memo(SideEffects);
