import React, { FC } from 'react';
import {
  Platform,
  TouchableNativeFeedback,
  TouchableOpacity,
  TouchableWithoutFeedbackProps,
  View,
  ViewStyle,
  StyleProp,
} from 'react-native';

import { TOUCHABLE_ACTIVE_OPACITY } from 'components/ui-constants';

export interface Props extends TouchableWithoutFeedbackProps {
  style?: StyleProp<ViewStyle>;
  activeOpacity?: number;
  underlayColor?: string;
}

export const Touchable: FC<Props> = Platform.select<any>({
  web: ({ children, ...props }: Props) => (
    <TouchableOpacity
      accessibilityRole="button"
      activeOpacity={TOUCHABLE_ACTIVE_OPACITY}
      {...props}
    >
      {children}
    </TouchableOpacity>
  ),
  ios: ({ children, ...props }: Props) => (
    <TouchableOpacity
      accessibilityRole="button"
      activeOpacity={TOUCHABLE_ACTIVE_OPACITY}
      {...props}
    >
      {children}
    </TouchableOpacity>
  ),
  android: ({ style, children, ...props }: Props) => (
    <TouchableNativeFeedback accessibilityRole="button" {...props}>
      <View style={style}>{children}</View>
    </TouchableNativeFeedback>
  ),
});

Touchable.displayName = 'Touchable';
