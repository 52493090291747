import { useQuery, useMutation } from '@apollo/client';

import { getCurrentUser } from 'api/queries/getCurrentUser';
import { GetCurrentUser } from 'api/queries/types/GetCurrentUser';
import {
  GetCountryByCode,
  GetCountryByCodeVariables,
} from 'api/queries/types/GetCountryByCode';
import { getCountryByCode } from 'api/queries/getCountryByCode';
import { countryCode } from 'services/i18n';
import { deleteUser } from 'api/mutations/deleteUser';
import {
  DeleteUser,
  DeleteUserVariables,
} from 'api/mutations/types/DeleteUser';
import { updateCurrentUser } from 'api/mutations/updateCurrentUser';
import {
  UpdateCurrentUser,
  UpdateCurrentUserVariables,
} from 'api/mutations/types/UpdateCurrentUser';

export const useUserQuery = () => {
  return useQuery<GetCurrentUser>(getCurrentUser, {
    skip: true,
  });
};

export const useCurrentCountryQuery = () => {
  return useQuery<GetCountryByCode, GetCountryByCodeVariables>(
    getCountryByCode,
    {
      skip: true,
      variables: {
        code: countryCode,
      },
    },
  );
};

export const useDeactivateUserMutation = () => {
  return useMutation<DeleteUser, DeleteUserVariables>(deleteUser);
};

export const useUserUpdateMutation = () => {
  return useMutation<UpdateCurrentUser, UpdateCurrentUserVariables>(
    updateCurrentUser,
    {
      update(cache, mutationResult) {
        return cache.writeQuery({
          query: getCurrentUser,
          data: mutationResult.data,
        });
      },
    },
  );
};
