// @TODO: Move this module to device service
import { Platform, PlatformOSType } from 'react-native';
import DeviceService from 'services/device';
import { deviceHeight, deviceWidth } from 'ui/spacing';

 

export const DIMENSIONS = {
  width: deviceWidth,
  twoThirdsWidth: deviceWidth * 0.67,
  twoThirdsHeight: deviceHeight * 0.67,
  threeQuartersHeight: deviceHeight * 0.75,
  halfWidth: deviceWidth / 2,
  height: deviceHeight,
};

export type Platforms = 'ios' | 'android';

interface Device {
  uuid: string;
  platform: PlatformOSType;
  version: string;
  is: {
    ios: boolean;
    android: boolean;
    native: boolean;
    web: boolean;
  };
}

const device: Device = {
  uuid: DeviceService.getUniqueId(),
  platform: Platform.OS,
  version: DeviceService.getReadableVersion(),
  is: {
    ios: Platform.OS === 'ios',
    android: Platform.OS === 'android',
    native: Platform.OS === 'ios' || Platform.OS === 'android',
    web: Platform.OS === 'web',
  },
};

export const isSmallScreen = DIMENSIONS.height < 600;
export const isBezelLess =
  DIMENSIONS.height === 812 ||
  DIMENSIONS.width === 812 ||
  DIMENSIONS.height > 812 ||
  DIMENSIONS.width > 812;

export default device;
