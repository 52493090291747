import React from 'react';
import { View } from 'react-native';

interface Props {
  flex?: number;
  margin?: number;
  padding?: number;
}

export const Spacer = ({ margin, padding, flex }: Props) => {
  return <View style={{ margin, padding, flex }} />;
};
