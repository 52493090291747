import { useEffect } from 'react';
import { AppState, AppStateStatus } from 'react-native';

const useForegroundHandler = (handler: () => void) =>
  useEffect(() => {
    const subscription = AppState.addEventListener('change', (state: AppStateStatus) => {
      if (state === 'active') {
        handler();
      }
    });

    return () => {
      subscription.remove();
    };
  }, [handler]);

export default useForegroundHandler;
