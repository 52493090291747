import React, { Suspense, lazy, useState, useEffect } from 'react';
import { NavigationAction } from '@react-navigation/core';

import { DEFAULT_APP_SETTINGS } from 'app/settings';
import { ThemeProvider } from 'ui/themes/ThemeProvider';
import { useAuth } from 'services/auth/useAuth';
import useAppSettings from 'hooks/useAppSettings';
import SideEffects from 'components/SideEffects';
import LoadingBootstrap from 'components/Loading/LoadingBootstrap';
import { getInitialNavigateAction } from 'navigation/utils/getInitialNavigateAction';
import { AuthContext } from 'services/auth/context';
import { GoogleOAuthProvider } from '@react-oauth/google';
import config from 'app/config';

const Navigation = lazy(() => import('navigation/Navigation'));
const PublicNavigation = lazy(() => import('navigation/PublicNavigation'));

interface BootstrapState {
  bootstrapped?: true;
  user: AuthContext['user'];
  initialNavigateAction?: NavigationAction;
}

const useBootstrap = () => {
  const [state, setState] = useState<BootstrapState>({
    bootstrapped: undefined,
    user: undefined,
    initialNavigateAction: undefined,
  });
  const { bootstrapped, user } = useAuth();

  useEffect(() => {
    if (bootstrapped) {
      const run = async () => {
        const action = await getInitialNavigateAction(user, true);

        setState({
          bootstrapped,
          user,
          initialNavigateAction: action,
        });
      };

      run();
    }
  }, [bootstrapped, user]);

  return state;
};

const Bootstrap = () => {
  const { bootstrapped, user, initialNavigateAction } = useBootstrap();
  const [theme] = useAppSettings('theme', DEFAULT_APP_SETTINGS.theme);

  if (!bootstrapped || !theme || !initialNavigateAction) {
    return <LoadingBootstrap />;
  }

  if (!user) {
    return (
      <GoogleOAuthProvider clientId={config.GOOGLE_APP_ID}>
        <ThemeProvider theme={theme}>
          <Suspense fallback={<div />}>
            <PublicNavigation initialNavigateAction={initialNavigateAction} />
          </Suspense>
        </ThemeProvider>
      </GoogleOAuthProvider>
    );
  }

  // initialPathAndParams prop will be applied on the web only
  // in future we want to migrate to rn v5 and start to use initialState
  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_APP_ID}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<div />}>
          <Navigation initialNavigateAction={initialNavigateAction}>
            <SideEffects />
          </Navigation>
        </Suspense>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
};

export default Bootstrap;
