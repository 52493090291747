/* eslint-disable import/no-duplicates */
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/remote-config';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/messaging';
import 'firebase/analytics';

import config from 'app/config';

firebase.initializeApp(config.FIREBASE as object);

const app = firebase.app;
const auth = firebase.auth;
const functions = firebase.functions;
const database = firebase.database;
const firestore = firebase.firestore;
const remoteConfig = firebase.remoteConfig;
const messaging = firebase.messaging;
const storage = firebase.storage;
const analytics = firebase.analytics;

const crashlytics = () => {
  return {
    recordError: (...args: unknown[]) => console.log('recordError stub', args),
  };
};

const notifications = () => {
  return {
    onNotification() {
      return () => {
        console.log('onNotification stub');
      };
    },
    onNotificationOpened() {
      return () => {
        console.log('onNotificationOpened stub');
      };
    },
  };
};

const dynamicLinks = () => {
  return {
    buildShortLink({ link }) {
      return Promise.resolve(link);
    },
  };
};

export {
  app,
  firestore,
  auth,
  functions,
  database,
  messaging,
  storage,
  remoteConfig,
  crashlytics,
  analytics,
  notifications,
  dynamicLinks,
};
