import { AppEventsLogger } from 'services/facebook';

import { Event, Provider } from './types';

export class FacebookProvider implements Provider {
  logEvent = ({ name, params }: Event) => {
    if (params) {
      AppEventsLogger.logEvent(name, params);
    }
    AppEventsLogger.logEvent(name);
  };
}
