import { ThemeContext } from '@ui-kitten/components/theme/theme/themeContext';
import { useContext } from 'react';

interface Theme {
  [key: string]: string;
}

const useTheme = (): Theme => {
  return useContext(ThemeContext);
};

export default useTheme;
