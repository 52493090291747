import moment from 'moment';
import { GENDER_NAMES, GENDER, PROFILE_TYPE } from 'app/constants';
import { Interest } from 'api/fragments/types/Interest';
import { hasEmoji } from './string';

interface Kol {
  tag: (key: number, email: string) => void;
  referral: (key: number, email: string) => void;
}

declare global {
  interface Window {
    _kol: Kol;
  }
}

export const getInterestsAsLabel = (nodes?: Interest[] | null) =>
  nodes ? nodes.map(i => i?.label).join(',') : '';


export const getInterestsList = (user, own) => {
  return [
    user.showInterests || own
      ? getInterestsAsLabel(user?.mainInterests?.nodes)
      : null,
  ]
    .filter(i => i !== null)
    .join(' • ');
};

export const getInterestsAsHashtag = (nodes?: Interest[] | null) =>
  nodes ? nodes.map(i => `#${i?.label}`).join(' ') : '';

export const getAge = (date: string) => moment().diff(moment(date), 'years');

export const getGenderName = (gender: GENDER | null) =>
  GENDER_NAMES[gender ?? GENDER.OTHER];

export const getGenderShortName = (gender: GENDER | null) =>
  getGenderName(gender).charAt(0);

export const initialiseFullName = name =>
  name
    ? name
        .split(' ')
        .slice(0, 1)
        .filter(i => !hasEmoji(i))
        .map(i => i[0])
        .join('')
    : '';

export const isProfessional = (role?: string) => role === PROFILE_TYPE.PRO;

export const getMentionIds = (text: string) => {
  if (!text) return [];

  const pattern = /@\[([^\]]+?)\]\(([^\]]+?)\)/;
  const matches = text.match(new RegExp(pattern, 'g')) || [];

  return matches.map((str: string) => {
    const match = str.match(new RegExp(pattern, 'i'));
    return match?.[2];
  });
};

export const tagUserEmail = async (
  key: number,
  email: string,
  isReferral?: boolean,
) => {
  if (window._kol) {
    window._kol.tag(key, email);
    if (isReferral) {
      window._kol.referral(key, email);
    }
  } else {
    await fetch(`https://tags.kickofflabs.com/139301/${key}/add`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (isReferral) {
      await fetch(`https://tags.kickofflabs.com/139301/${key}/referral`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
        }),
      });
    }
  }
};
