import { FirebaseAnalyticsTypes } from 'services/firebase';

import { Event, Provider } from './types';

export class FirebaseProvider implements Provider {
  constructor(private provider: FirebaseAnalyticsTypes.Module) {
    this.provider = provider;
  }

  logEvent = ({ name, params }: Event) => this.provider.logEvent(name, params);
}
