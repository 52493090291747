export const pluralize = (text = '', count = 1, inclusive = false): string => {
  const pluralized = count === 1 ? text : `${text}s`;

  return (inclusive ? count + ' ' : '') + pluralized;
};

export const getCharCodeLength = (value: string) =>
  value ? value.charCodeAt(0).toString().length : 1;

export const hasEmoji = (value: string) => getCharCodeLength(value) > 4;

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea');
  el.value = str;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document?.getSelection().rangeCount > 0
      ? document?.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const capitalCase = (str) => {
  return str
    .toLowerCase()
    .replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());
};

export const pascalSnakeCase = (str) => {
  return str
    .match(/[A-Za-z0-9]+/g)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('_');
};
