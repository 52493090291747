import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError, ErrorHandler } from '@apollo/client/link/error';

import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { invariant } from 'ts-invariant';

import { typeDefs } from 'api/typeDefs';
import { resolvers } from 'api/resolvers';

import { Session } from 'services/session';
import isReactNativeFile from './isReactNativeFile';
import { isWeb } from "../utils/platform";

const cache = new InMemoryCache({});

class InvalidRefreshToken extends Error {
  constructor() {
    super('Your session has expired, please log in');
  }
}

export interface PresetConfig {
  // request?: (operation: Operation) => Promise<void> | void;
  request?: (operation: any) => Promise<void> | void;
  uri: string;
  headers?: object;
  onError?: ErrorHandler;
  name?: string;
  version?: string;
  assumeImmutableResults?: boolean;
  session: Session;
}

export default class DefaultClient<TCache> extends ApolloClient<TCache> {
  constructor(config: PresetConfig) {
    const {
      uri,
      headers,
      onError: errorCallback,
      name,
      version,
      session,
    } = config;

    const refreshLink = setContext(async () => {
      const isExpired = await session.isExpired();

      if (!isExpired) {
        return {};
      }

      const refreshToken = await session.getRefreshToken();
      const response = await fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          query: `
            mutation($input: UpdateTokenInput!) {
              data: updateToken(input: $input) {
                accessToken
                refreshToken
              }
            }
          `,
          variables: {
            input: {
              refreshToken,
            },
          },
        }),
      });

      const result = await response.json();
      const data = result?.data?.data;

      if (!data) {
        await session.delete();

        throw new InvalidRefreshToken();
      }

      return await session.update(data);
    });

    const tokenLink = setContext(async () => {
      const token = await session.getAccessToken();

      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${token}`,
        },
      };
    });

    const errorLink = errorCallback
      ? onError(errorCallback)
      : onError(({ graphQLErrors, networkError }) => {
          if (graphQLErrors) {
            graphQLErrors.forEach(({ message, locations, path }) =>
              // tslint:disable-next-line
              invariant.warn(
                `[GraphQL error]: Message: ${message}, Location: ` +
                  `${locations}, Path: ${path}`,
              ),
            );
          }
          if (networkError) {
            // tslint:disable-next-line
            invariant.warn(`[Network error]: ${networkError}`);
          }
        });

    const httpLink = createUploadLink({
      uri: uri || '/graphql',
      fetch,
      fetchOptions: {},
      credentials: 'same-origin',
      headers: headers || {},
      isExtractableFile: isWeb ? undefined : isReactNativeFile,
    });

    const link = ApolloLink.from(
      [errorLink, refreshLink, tokenLink, httpLink].filter(
        x => !!x,
      ) as ApolloLink[],
    );

    // const activeResolvers = resolvers;
    // const activeTypeDefs = typeDefs;
    // const activeFragmentMatcher = fragmentMatcher;

    // super hacky, we will fix the types eventually
    super({
      cache,
      link,
      name,
      version,
      resolvers,
      typeDefs,
    } as any);
  }
}
