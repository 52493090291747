import { useEffect } from 'react';
import PresenceService from 'modules/chat/services/PresenceService';
import { useAuth } from 'services/auth/useAuth';
import useForegroundHandler from './useForegroundHandler';
import useBackgroundHandler from './useBackgroundHandler';

const on = () => PresenceService.online();
const off = () => PresenceService.offline();

export const usePresence = () => {
  const { id } = useAuth();

  useEffect(() => {
    if (id) {
      return PresenceService.connect(id);
    }

    return undefined;
  }, [id]);

  useForegroundHandler(on);
  useBackgroundHandler(off);
};
