import { gql } from '@apollo/client';

import { UserMeta } from './UserMeta';
import { Country } from './Country';
import { Attachment } from './Attachment';
import { Interest } from './Interest';
import { Album } from './Album';

export const typename = 'User';

export const User = gql`
  fragment User on User {
    id
    role
    email
    gender
    online
    fullName
    dateOfBirth
    phoneNumber
    aboutMe
    education
    age
    showAge
    showInterests
    showContact
    profileCompleted
    createdAt
    updatedAt
    followersCount
    followingCount
    mainInterestIds
    speciality
    degree
    officeAddress
    officePhoneNumber
    workingHours
    seenNotificationModal
    seenPromotion
    retentionSurveyDate
    retentionSurveyPassed
    firstThreadCreated
    officeCountry {
      ...Country
    }
    mainInterests {
      nodes {
        ...Interest
      }
    }
    interestIds
    interests {
      nodes {
        ...Interest
      }
    }
    avatar {
      ...Attachment
    }
    coverPhoto {
      ...Attachment
    }
    meta {
      ...UserMeta
    }
    country {
      ...Country
    }
    officeCountry {
      ...Country
    }
    albums {
      ...Album
    }
  }
  ${Interest}
  ${UserMeta}
  ${Country}
  ${Attachment}
  ${Interest}
  ${Album}
`;
