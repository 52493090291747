import { database, FirebaseDatabaseTypes } from 'services/firebase';

import { onErrorHandler } from 'utils/reportError';

const offlineStatus = {
  state: 0,
};

const onlineStatus = {
  state: 1,
};

class PresenceService {
  constructor(private db: FirebaseDatabaseTypes.Module) {}

  private getConnectedRef = () => this.db.ref('.info/connected');

  private getRef = (userId: string) =>
    this.db.ref(`/presence_status/${userId}`);

  connect(userId: string) {
    const connectedRef = this.getConnectedRef();
    const presenceStatusRef = this.getRef(userId);

    const originalCallback = connectedRef.on(
      'value',
      () => {
        presenceStatusRef
          .onDisconnect()
          .set(offlineStatus)
          .then(() => presenceStatusRef.set(onlineStatus))
          .catch(onErrorHandler);
      },
      onErrorHandler,
    );

    return () => {
      connectedRef.off('value', originalCallback);
    };
  }

  offline() {
    this.db.goOffline();
  }

  online() {
    this.db.goOnline();
  }
}

export default new PresenceService(database());
