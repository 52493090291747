import config from 'app/config';
import Session from 'services/session';
import ApolloClient from './client';

export const createClient = () => {
  return new ApolloClient({
    uri: `${config.API}/graphql`,
    headers: {
      'x-app-version': config.APP_VERSION,
    },
    name: 'native',
    version: config.APP_VERSION,
    session: Session,
  });
};
