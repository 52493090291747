export const mapping = {
  strict: {
    'text-font-family': 'Montserrat',

    'text-heading-1-font-size': 32,
    'text-heading-1-line-height': 48,
    'text-heading-1-font-weight': '500',

    'text-heading-2-font-size': 28,
    'text-heading-2-line-height': 42,
    'text-heading-2-font-weight': '500',

    'text-heading-3-font-size': 26,
    'text-heading-3-line-height': 40,
    'text-heading-3-font-weight': '500',

    'text-heading-4-font-size': 24,
    'text-heading-4-line-height': 32,
    'text-heading-4-font-weight': '500',

    'text-heading-5-font-size': 22,
    'text-heading-5-line-height': 32,
    'text-heading-5-font-weight': '500',

    'text-heading-6-font-size': 20,
    'text-heading-6-line-height': 24,
    'text-heading-6-font-weight': '500',

    'text-subtitle-1-font-size': 17,
    'text-subtitle-1-line-height': 24,
    'text-subtitle-1-font-weight': '600',

    'text-subtitle-2-font-size': 15,
    'text-subtitle-2-line-height': 22,
    'text-subtitle-2-font-weight': '600',

    'text-paragraph-1-font-size': 17,
    'text-paragraph-1-line-height': 24,
    'text-paragraph-1-font-weight': '400',

    'text-paragraph-2-font-size': 15,
    'text-paragraph-2-line-height': 22,
    'text-paragraph-2-font-weight': '400',

    'text-caption-1-font-size': 15,
    'text-caption-1-line-height': 22,
    'text-caption-1-font-weight': '400',

    'text-caption-2-font-size': 15,
    'text-caption-2-line-height': 22,
    'text-caption-2-font-weight': '600',

    'text-label-font-size': 13,
    'text-label-line-height': 18,
    'text-label-font-weight': '300',

    'size-medium': 36,
  },
  components: {
    Icon: {
      meta: {
        parameters: {
          width: {
            type: 'number',
          },
          height: {
            type: 'number',
          },
          color: {
            type: 'string',
          },
        },
        variantGroups: {
          status: {
            basic: { default: false },
            primary: { default: true },
            success: { default: false },
            info: { default: false },
            warning: { default: false },
            danger: { default: false },
          },
          size: {
            tiny: { default: false },
            small: { default: false },
            medium: { default: true },
            large: { default: false },
            giant: { default: false },
          },
        },
        states: {},
        appearances: {
          default: {
            default: true,
          },
        },
      },
      appearances: {
        default: {
          mapping: {
            color: 'color-basic-500',
          },
          variantGroups: {
            status: {
              basic: { color: 'color-basic-500' },
              primary: { color: 'color-primary-500' },
              success: { color: 'color-success-500' },
              info: { color: 'color-info-500' },
              warning: { color: 'color-warning-500' },
              danger: { color: 'color-danger-500' },
            },
            size: {
              tiny: {
                width: 8,
                height: 8,
              },
              small: {
                width: 16,
                height: 16,
              },
              medium: {
                width: 24,
                height: 24,
              },
              large: {
                width: 32,
                height: 32,
              },
              giant: {
                width: 64,
                height: 64,
              },
            },
          },
        },
      },
    },
    ButtonGroup: {
      meta: {
        parameters: {
          textDecorationLine: {
            type: 'string',
          },
        },
      },
      appearances: {
        outline: {
          mapping: {},
          variantGroups: {
            size: {
              tiny: {
                borderRadius: 24,
              },
              small: {
                borderRadius: 24,
              },
              medium: {
                borderRadius: 24,
              },
              large: {
                borderRadius: 24,
              },
              giant: {
                borderRadius: 30,
              },
            },
          },
        },
      },
    },
    Button: {
      meta: {
        parameters: {
          textDecorationLine: {
            type: 'string',
          },
        },
      },
      appearances: {
        filled: {
          mapping: {},
          variantGroups: {
            status: {
              primary: {
                textFontWeight: '600',
              },
            },
            size: {
              tiny: {
                borderRadius: 24,
              },
              small: {
                borderRadius: 24,
              },
              medium: {
                borderRadius: 24,
              },
              large: {
                borderRadius: 24,
              },
              giant: {
                borderRadius: 30,
              },
            },
          },
        },
        outline: {
          mapping: {},
          variantGroups: {
            status: {
              primary: {
                backgroundColor: 'background-basic-color-1',
                state: {
                  hover: {
                    backgroundColor: '#e8f4f7',
                  },
                },
              },
              basic: {
                textColor: 'color-basic-100',
                borderColor: 'color-basic-100',
                state: {
                  hover: {
                    backgroundColor: 'color-basic-300',
                  },
                },
              },
            },
          },
        },
        ghost: {
          variantGroups: {
            status: {
              basic: {
                textColor: 'text-basic-color',
                iconTintColor: 'text-basic-color',
              },
            },
          },
        },
        rounded: {
          variantGroups: {
            status: {
              primary: {
                textFontWeight: '600',
              },
            },
            size: {
              large: {
                minWidth: 75,
                minHeight: 60,
                borderRadius: 30,
              },
            },
          },
        },
        system: {
          mapping: {},
          variantGroups: {
            status: {
              primary: {
                textFontWeight: '400',
                textColor: 'color-system-control-default',
                textFontSize: 17,
                borderColor: 'transparent',
                backgroundColor: 'transparent',
                state: {
                  active: {
                    backgroundColor: 'color-basic-400',
                    borderColor: 'transparent',
                  },
                },
              },
            },
            size: {
              large: {
                minWidth: 75,
                minHeight: 60,
                borderRadius: 30,
              },
            },
          },
        },
      },
    },
    Select: {
      meta: {
        parameters: {
          borderBottomWidth: { type: 'number' },
          borderBottomColor: { type: 'string' },
        },
      },
      appearances: {
        default: {
          variantGroups: {
            status: {
              basic: {
                borderColor: 'border-basic-color-5',
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
              primary: {
                borderColor: 'border-basic-color-5',
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
              danger: {
                backgroundColor: 'background-basic-color-1',
                borderBottomWidth: 2,
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
            },
            size: {
              medium: {
                borderWidth: 0,
                borderBottomWidth: 1,
                borderRadius: 0,
                textFontSize: 'text-paragraph-1-font-size',
                textFontWeight: 'text-paragraph-1-font-weight',
                textLineHeight: 'text-paragraph-1-line-height',
              },
            },
          },
        },
        ghost: {
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'color-basic-200',
                textMarginHorizontal: 0,
                borderRadius: 0,
              },
            },
            size: {
              small: {
                minHeight: 32,
                textFontSize: 15,
                placeholderFontSize: 15,
              },
            },
          },
        },
      },
    },
    Datepicker: {
      meta: {},
      appearances: {
        default: {
          variantGroups: {
            status: {
              basic: {
                borderColor: 'border-basic-color-5',
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
                state: {
                  hover: {
                    backgroundColor: 'red',
                  },
                },
              },
              primary: {
                borderColor: 'border-basic-color-5',
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
              danger: {
                backgroundColor: 'background-basic-color-1',
                borderBottomWidth: 2,
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
            },
            size: {
              medium: {
                borderWidth: 0,
                borderRadius: 0,
                textColor: 'text-basic-color',
              },
            },
          },
        },
      },
    },
    Input: {
      meta: {
        parameters: {
          borderBottomWidth: { type: 'number' },
          borderBottomColor: { type: 'string' },
        },
      },
      appearances: {
        default: {
          variantGroups: {
            status: {
              basic: {
                borderColor: 'border-basic-color-5',
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
              primary: {
                borderColor: 'border-basic-color-5',
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
              danger: {
                backgroundColor: 'background-basic-color-1',
                borderBottomWidth: 2,
                paddingHorizontal: 0,
                textMarginHorizontal: 0,
              },
            },
            size: {
              medium: {
                borderWidth: 0,
                borderBottomWidth: 1.1,
                borderRadius: 0,
              },
            },
          },
        },
        rounded: {
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'background-basic-color-1',
                paddingHorizontal: 10,
                textMarginHorizontal: 0,
                state: {
                  focused: {
                    borderWidth: 0,
                    borderBottomWidth: 0,
                  },
                  hover: {
                    borderWidth: 0,
                    borderBottomWidth: 1,
                  },
                },
              },
              primary: {
                backgroundColor: 'color-basic-200',
                paddingHorizontal: 10,
                textMarginHorizontal: 0,
                borderColor: 'color-basic-500',
                state: {
                  focused: {
                    borderColor: 'color-primary-500',
                    borderWidth: 1,
                  },
                },
              },
            },
            size: {
              small: {
                textFontSize: 15,
                borderRadius: 19,
                borderBottomWidth: 1,
              },
              medium: {
                textFontSize: 15,
                borderRadius: 30,
                borderWidth: 0,
                borderBottomWidth: 0,
              },
              large: {
                textFontSize: 17,
                borderRadius: 30,
                borderWidth: 0,
                borderBottomWidth: 0,
                textMarginHorizontal: 10,
              },
            },
          },
        },
        ghost: {
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'color-basic-100',
                paddingHorizontal: 24,
                textMarginHorizontal: 0,
                borderWidth: 0,
                borderColor: 'transparent',
                state: {
                  focused: {
                    borderWidth: 0,
                    borderColor: 'transparent',
                  },
                },
              },
            },
            size: {
              medium: {
                borderWidth: 0,
                borderRadius: 0,
              },
            },
          },
        },
        search: {
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'color-basic-100',
                paddingHorizontal: 8,
                textMarginHorizontal: 0,
                borderWidth: 0,
                borderColor: 'transparent',
                state: {
                  focused: {
                    borderWidth: 0,
                    borderColor: 'transparent',
                  },
                },
              },
            },
            size: {
              small: {
                borderWidth: 0,
                borderRadius: 20,
              },
            },
          },
        },
        ghostBordered: {
          variantGroups: {
            status: {
              basic: {
                backgroundColor: 'color-basic-200',
                paddingHorizontal: 8,
                textMarginHorizontal: 0,
                borderWidth: 0,
                borderBottomWidth: 1,
                borderColor: 'color-basic-400',
                state: {
                  active: {
                    borderWidth: 0,
                    borderBottomWidth: 2,
                    borderColor: 'color-primary-500',
                  },
                },
              },
              success: {
                paddingHorizontal: 5,
                textMarginHorizontal: 2,
              },
              danger: {
                paddingHorizontal: 5,
                textMarginHorizontal: 2,
              },
            },
          },
        },
      },
    },
    CheckBox: {
      appearances: {
        default: {
          mapping: {
            textFontSize: 'text-paragraph-2-font-size',
            textFontWeight: 'text-paragraph-2-font-weight',
            textLineHeight: 'text-paragraph-2-line-height',
          },
        },
      },
    },
    List: {
      appearances: {
        default: {
          mapping: {
            backgroundColor: 'background-basic-color-1',
          },
        },
      },
    },
    ListItem: {
      appearances: {
        default: {
          mapping: {
            paddingHorizontal: 24,
            paddingVertical: 16,
          },
        },
      },
    },
    TabBar: {
      meta: {
        parameters: {
          borderBottomWidth: {
            type: 'number',
          },
          borderBottomColor: {
            type: 'string',
          },
        },
      },
      appearances: {
        default: {
          mapping: {
            borderBottomColor: 'color-basic-500',
            borderBottomWidth: 1,
            indicatorHeight: 2,
            indicatorBorderRadius: 0,
            paddingVertical: 6,
          },
        },
      },
    },
    Tab: {
      appearances: {
        default: {
          mapping: {
            textFontSize: 15,
            textLineHeight: 21,
            textFontWeight: '500',
            textColor: 'color-info-500',
          },
        },
      },
    },
    Avatar: {
      appearances: {
        default: {
          mapping: {},
          variantGroups: {
            shape: {
              rounded: {
                roundCoefficient: 0.08,
              },
            },
          },
        },
      },
    },
  },
};
