import { light } from '@eva-design/eva';

export const healthreadLight = {
  ...light,

  'color-nav-header': '#71B8CB',
  'color-nav-bar': '#71B8CB',
  'color-nav-bar-inactive': '#A3AFC8',

  'color-basic-100': '#FFFFFF',
  'color-basic-200': '#F7F9FC',
  'color-basic-300': '#EDF1F7',
  'color-basic-400': '#E4E9F2',
  'color-basic-500': '#C1CEDE',
  'color-basic-600': '#8F9BB3',
  'color-basic-700': '#2E3A59',
  'color-basic-800': '#222B45',
  'color-basic-900': '#1A2138',
  'color-basic-1000': '#151A30',
  'color-basic-1100': '#101426',

  'color-primary-100': '#E6FCFA',
  'color-primary-200': '#CDF9F9',
  'color-primary-300': '#AFEBEF',
  'color-primary-400': '#95D5DF',
  'color-primary-500': '#71B8CB',
  'color-primary-600': '#5294AE',
  'color-primary-700': '#387292',
  'color-primary-800': '#245275',
  'color-primary-900': '#153B61',
  'color-primary-transparent-100': 'rgba(113, 184, 203, 0.08)',
  'color-primary-transparent-200': 'rgba(113, 184, 203, 0.16)',
  'color-primary-transparent-300': 'rgba(113, 184, 203, 0.24)',
  'color-primary-transparent-400': 'rgba(113, 184, 203, 0.32)',
  'color-primary-transparent-500': 'rgba(113, 184, 203, 0.4)',
  'color-primary-transparent-600': 'rgba(113, 184, 203, 0.48)',
  'color-success-100': '#E8FCF0',
  'color-success-200': '#D1F9E6',
  'color-success-300': '#B5EED8',
  'color-success-400': '#9BDECB',
  'color-success-500': '#78C8B9',
  'color-success-600': '#57ACA4',
  'color-success-700': '#3C8F90',
  'color-success-800': '#266C74',
  'color-success-900': '#175260',
  'color-success-transparent-100': 'rgba(120, 200, 185, 0.08)',
  'color-success-transparent-200': 'rgba(120, 200, 185, 0.16)',
  'color-success-transparent-300': 'rgba(120, 200, 185, 0.24)',
  'color-success-transparent-400': 'rgba(120, 200, 185, 0.32)',
  'color-success-transparent-500': 'rgba(120, 200, 185, 0.4)',
  'color-success-transparent-600': 'rgba(120, 200, 185, 0.48)',
  'color-info-100': '#ECF3FA',
  'color-info-200': '#DBE7F6',
  'color-info-300': '#BFCFE6',
  'color-info-400': '#A2B2CD',
  'color-info-500': '#7C8CAC',
  'color-info-600': '#5A6B93',
  'color-info-700': '#3E4E7B',
  'color-info-800': '#273563',
  'color-info-900': '#172352',
  'color-info-transparent-100': 'rgba(124, 140, 172, 0.08)',
  'color-info-transparent-200': 'rgba(124, 140, 172, 0.16)',
  'color-info-transparent-300': 'rgba(124, 140, 172, 0.24)',
  'color-info-transparent-400': 'rgba(124, 140, 172, 0.32)',
  'color-info-transparent-500': 'rgba(124, 140, 172, 0.4)',
  'color-info-transparent-600': 'rgba(124, 140, 172, 0.48)',
  'color-warning-100': '#FEF4DF',
  'color-warning-200': '#FDE7C0',
  'color-warning-300': '#FBD5A0',
  'color-warning-400': '#F7C388',
  'color-warning-500': '#F3A761',
  'color-warning-600': '#D08146',
  'color-warning-700': '#AE6030',
  'color-warning-800': '#8C421E',
  'color-warning-900': '#742D12',
  'color-warning-transparent-100': 'rgba(243, 167, 97, 0.08)',
  'color-warning-transparent-200': 'rgba(243, 167, 97, 0.16)',
  'color-warning-transparent-300': 'rgba(243, 167, 97, 0.24)',
  'color-warning-transparent-400': 'rgba(243, 167, 97, 0.32)',
  'color-warning-transparent-500': 'rgba(243, 167, 97, 0.4)',
  'color-warning-transparent-600': 'rgba(243, 167, 97, 0.48)',
  'color-danger-100': '#FFEBDD',
  'color-danger-200': '#FFD2BC',
  'color-danger-300': '#FFB49B',
  'color-danger-400': '#FF9782',
  'color-danger-500': '#FF6759',
  'color-danger-600': '#DB4141',
  'color-danger-700': '#B72C37',
  'color-danger-800': '#931C2F',
  'color-danger-900': '#7A112A',
  'color-danger-transparent-100': 'rgba(255, 103, 89, 0.08)',
  'color-danger-transparent-200': 'rgba(255, 103, 89, 0.16)',
  'color-danger-transparent-300': 'rgba(255, 103, 89, 0.24)',
  'color-danger-transparent-400': 'rgba(255, 103, 89, 0.32)',
  'color-danger-transparent-500': 'rgba(255, 103, 89, 0.4)',
  'color-danger-transparent-600': 'rgba(255, 103, 89, 0.48)',
  'color-control': 'rgba(255, 103, 89, 0.48)',

  'color-system-control-default': '#007BFA',
  'color-facebook': '#2553b4',
  'color-google': '#d34836',
};
