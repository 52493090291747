import { useEffect } from 'react';
import { Linking } from 'react-native';
import { useNavigation } from 'react-navigation-hooks';
import { NavigationActions } from '@react-navigation/core';
import urlParse from 'url-parse';

import { NAV_TYPES } from 'app/constants';
import { useAuth } from 'services/auth/useAuth';
import useAppSettings, { KEY_TUTORIAL_SEEN } from 'hooks/useAppSettings';
import { EVENTS, useAnalyticsLogger } from '../services/analytics';

export const useDeepLinkOpened = () => {
  const nav = useNavigation();
  const { user } = useAuth();
  const [seen] = useAppSettings(KEY_TUTORIAL_SEEN, false);
  const trackOpenDeepLink = useAnalyticsLogger(EVENTS.DEEP_LINK_OPENED);

  useEffect(() => {
    const handleOpenUrl = (data: { url: string }) => {
      const urlObj = urlParse(data?.url, true);
      if (user && seen === true && urlObj.query.fbclid) {
        nav.dispatch(
          NavigationActions.navigate({
            routeName: NAV_TYPES.GROUPS_DISCOVER,
          }),
        );
      }
      if (
        user &&
        seen === true &&
        urlObj.query.ref &&
        urlObj.pathname.includes('/groups/group')
      ) {
        const id = urlObj.pathname.replace('/groups/group/', '');
        trackOpenDeepLink();
        const { imageUrl, title, subtitle, ref } = urlObj.query;
        nav.dispatch(
          NavigationActions.navigate({
            routeName: NAV_TYPES.GROUP_DETAILS,
            params: {
              id,
              imageUrl,
              title,
              subtitle,
              ref,
            },
          }),
        );
      }
      if (
        !user &&
        urlObj.query.ref &&
        urlObj.pathname.includes('/auth/sign_up')
      ) {
        const { ref } = urlObj.query;
        nav.dispatch(
          NavigationActions.navigate({
            routeName: NAV_TYPES.SIGN_UP,
            params: {
              ref,
            },
          }),
        );
      }
    };

    const getUrlAsync = async () => {
      const initialUrl = await Linking.getInitialURL();

      if (initialUrl) {
        setTimeout(() => {
          handleOpenUrl({ url: initialUrl });
        }, 500);
      };
    };

    getUrlAsync();

    const subscription = Linking.addEventListener('url', handleOpenUrl);

    return () => {
      subscription.remove();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, seen]);
};
