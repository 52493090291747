import React, { createContext, useCallback, useEffect } from 'react';

import { SessionToken } from 'api/fragments/types/SessionToken';
import { User } from 'api/fragments/types/User';
import AuthService from 'services/auth';
import { reportError } from 'utils/reportError';
import { tagUserEmail } from 'utils/user';
import { useUpdateDevice } from 'hooks/useUpdateDevice';
import { useAnalyticsLogger, EVENTS } from 'services/analytics';
import { useAuthReducer } from './useAuthReducer';
import {
  useUserQuery,
  useUserUpdateMutation,
  useCurrentCountryQuery,
  useDeactivateUserMutation,
} from './queries';
import { UserDeclineError } from './errors';

type AuthorizeCreator = <I>(
  provider: (input: I) => Promise<{ data?: { data: SessionToken | null } }>,
) => (input: I) => void;

type UpdateUserHandler = (input: {
  mainInterestIds: any;
  defaultAvatarId: number;
}) => void;

export interface AuthContext {
  bootstrapped: boolean;
  id?: string;
  user?: User;
  error?: Error;
  loading: boolean;
  authorizeCreator: AuthorizeCreator;
  authorizeCreatorForDelete : AuthorizeCreator;
  logout: () => void;
  deactivate: () => void;
  refetch: () => void;
  update: UpdateUserHandler;
  reset: () => void;
}

export const context = createContext<AuthContext>({} as AuthContext);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useAuthReducer();
  const userQuery = useUserQuery();
  const [updateUser] = useUserUpdateMutation();
  const countryQuery = useCurrentCountryQuery();
  const [deactivateUser] = useDeactivateUserMutation();
  const [updateDevice] = useUpdateDevice();
  const trackRegistration = useAnalyticsLogger(
    EVENTS.COMPLETED_REGISTRATION,
    true,
  );

  const authorizeCreator = useCallback<AuthorizeCreator>(
    (provider) => async (input, referral) => {
      try {
        dispatch({ type: 'request' });

        const result = await provider(input);

        await AuthService.authorize(result.data?.data);
        await countryQuery.refetch();

        const { data } = await userQuery.refetch();

        if (result.data?.data?.newUser) {
          trackRegistration();
          if (referral) {
            tagUserEmail(5757, referral);
          }
        }

        dispatch({ type: 'success', payload: data.data });
      } catch (error) {
        if (error instanceof UserDeclineError) {
          return dispatch({ type: 'success' });
        }
        dispatch({ type: 'failure', error });
        reportError({ error });
      }
    },
    [countryQuery, dispatch, trackRegistration, userQuery],
  );

  const authorizeCreatorForDelete = useCallback<AuthorizeCreator>(
    (provider) => async (input, referral) => {
      try {
        dispatch({ type: 'request' });

        const result = await provider(input);

        await AuthService.authorize(result.data?.data);
        await countryQuery.refetch();

        const { data } = await userQuery.refetch();

        await deactivateUser({
          variables: {
            id: data.data.id,
          },
        });
        await userQuery.client.clearStore();
        dispatch({ type: 'reset' });
        dispatch({ type: 'success', payload: undefined });
        // deactivate();
        // dispatch({ type: 'success', payload: data.data });
      } catch (error) {
        if (error instanceof UserDeclineError) {
          return dispatch({ type: 'success' });
        }
        dispatch({ type: 'failure', error });
        reportError({ error });
      }
    },
    [countryQuery, dispatch, trackRegistration, userQuery],
  );

  const update = useCallback<UpdateUserHandler>(
    async (input) => {
      try {
        dispatch({ type: 'request' });

        const { data } = await updateUser({
          variables: {
            input,
          },
        });
        dispatch({ type: 'success', payload: data?.data });
        return data?.data;
      } catch (error) {
        dispatch({ type: 'failure', error });
        reportError({ error });
        throw new Error(error);
      }
    },
    [dispatch, updateUser],
  );

  const refetch = useCallback<() => void>(async () => {
    try {
      dispatch({ type: 'request' });

      const { data } = await userQuery.refetch();
      dispatch({ type: 'success', payload: data?.data });
    } catch (error) {
      dispatch({ type: 'failure', error });
      reportError({ error });
      throw new Error(error);
    }
  }, [dispatch, updateUser]);

  const logout = useCallback(async () => {
    try {
      dispatch({ type: 'reset' });
      await updateDevice({
        active: false,
      });

      await userQuery.client.clearStore();
      await AuthService.logout();
    } catch (error) {
      dispatch({ type: 'failure', error });
      reportError({ error });
    }
  }, [dispatch, updateDevice, userQuery.client]);

  const deactivate = useCallback(async () => {
    try {
      if (state.user?.id) {
        await deactivateUser({
          variables: {
            id: state.user?.id,
          },
        });
        await userQuery.client.clearStore();
        dispatch({ type: 'reset' });
      }
    } catch (error) {
      dispatch({ type: 'failure', error });
      reportError({ error });
    }
  }, [deactivateUser, dispatch, state.user, userQuery.client]);

  const reset = useCallback(() => {
    dispatch({ type: 'reset' });
  }, [dispatch]);

  useEffect(() => {
    const run = async () => {
      try {
        const isAuthorized = await AuthService.isAuthorized();

        if (!isAuthorized) {
          return dispatch({ type: 'success', payload: undefined });
        }

        const { data } = await userQuery.refetch();
        await countryQuery.refetch();
        dispatch({ type: 'success', payload: data.data });
      } catch (error) {
        dispatch({ type: 'failure', error });
        reportError({ error });
      }
    };

    run();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <context.Provider
      value={{
        bootstrapped: state.bootstrapped,
        loading: state.loading,
        id: state.user?.id,
        user: state.user,
        error: state.error,
        authorizeCreator,
        authorizeCreatorForDelete,
        logout,
        deactivate,
        refetch,
        update,
        reset,
      }}
    >
      {children}
    </context.Provider>
  );
};
