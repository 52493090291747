import { gql } from '@apollo/client';

import { User } from 'api/fragments/User';

export const updateCurrentUser = gql`
  mutation UpdateCurrentUser($input: UpdateUserInput!) {
    data: updateCurrentUser(input: $input) {
      ...User
    }
  }
  ${User}
`;
