import { createSlice, Slice, SliceCaseReducers } from '@reduxjs/toolkit';
import { RootState } from '../store';

export const name = 'onboarding';
export interface OnboardingState {
  howDoYouFeelOpen: boolean;
  isRetentionsSurveyOpen: boolean;
  redirectToFeed: boolean;
  openHealthCheckIn: boolean;
}
const initialState: OnboardingState = {
  howDoYouFeelOpen: false,
  isRetentionsSurveyOpen: false,
  redirectToFeed: false,
  openHealthCheckIn: false,
};

export const onboardingSlice: Slice<
  OnboardingState,
  SliceCaseReducers<OnboardingState>
> = createSlice<OnboardingState, SliceCaseReducers<OnboardingState>>({
  name,
  initialState,
  reducers: {
    setHowDoYouFeelOpen: (state, action) => {
      state.howDoYouFeelOpen = action.payload;
    },
    setRetentionSurveyOpen: (state, action) => {
      state.isRetentionsSurveyOpen = action.payload;
    },
    setRedirectToFeed: (state, action) => {
      state.redirectToFeed = action.payload;
    },
    setOpenHealthCheckIn: (state, action) => {
      state.openHealthCheckIn = action.payload;
    },
  },
});

export const {
  setHowDoYouFeelOpen,
  setRetentionSurveyOpen,
  setRedirectToFeed,
  setOpenHealthCheckIn
} =
  onboardingSlice.actions;
export const selectHowDoYouFeelOpen = (state: RootState) =>
  state[name].howDoYouFeelOpen;

export const selectIsRetentionSurveyOpen = (state: RootState) =>
  state[name].isRetentionsSurveyOpen;

export const selectRedirectToFeed = (state: RootState) =>
  state[name].redirectToFeed;

export const selectOpenHealthCheckIn = (state: RootState) =>
  state[name].openHealthCheckIn;
