import decode from 'jwt-decode';
import AsyncStorage from '@react-native-async-storage/async-storage';

export interface Tokens {
  accessToken: string;
  refreshToken: string;
  firebaseToken: string;
}

export class Session {
  key = 'session';

  async isExpired() {
    const token = await this.getAccessToken();

    if (!token) {
      return false;
    }

    const { exp } = decode(token);

    return exp < Math.round(Date.now() / 1000) + 60;
  }

  async isAuthorized() {
    return !!(await this.getAccessToken());
  }

  async isFirstLaunch() {
    const launched = await AsyncStorage.getItem(`${this.key}-launched`);
    return launched !== 'true';
  }

  async getAccessToken() {
    return AsyncStorage.getItem(`${this.key}-access`);
  }

  async getRefreshToken() {
    return AsyncStorage.getItem(`${this.key}-refresh`);
  }

  async getFirebaseToken() {
    return AsyncStorage.getItem(`${this.key}-firebase`);
  }

  async setLaunched() {
    await AsyncStorage.setItem(`${this.key}-launched`, 'true');
  }

  async update(data: Tokens) {
    // @TODO: Looks like setMultiple method is broken
    await AsyncStorage.setItem(`${this.key}-access`, data.accessToken);
    await AsyncStorage.setItem(`${this.key}-refresh`, data.refreshToken);
    await AsyncStorage.setItem(`${this.key}-firebase`, data.firebaseToken);
  }

  async delete() {
    await AsyncStorage.multiRemove([
      `${this.key}-access`,
      `${this.key}-refresh`,
      `${this.key}-firebase`,
    ]);
  }
}

export default new Session();
